<div style="padding: 10px">
  <div class="collection-set">
    @for (collection of collections; track collection.id) {
      <mat-checkbox
        [checked]="selectedCollectionIds.has(collection.id)"
        (change)="onChange($event.checked, collection.id)"
      >
        {{ collection.name }}
      </mat-checkbox>
    }
  </div>

  <br />
  <button mat-raised-button color="primary" (click)="onAddFrames()">
    Submit
  </button>

  <app-mass-action-progress-bar [progressReport]="progressReport" />
</div>
