import { Injectable } from '@angular/core';
import { MlBackendService } from '../core/ml-backend.service';
import { firstValueFrom, Observable, throwError, Subject } from 'rxjs';
import { CollectionDto, LabelTypeDto } from './ml-data-types';
import { HttpErrorResponse } from '@angular/common/http';

export type CreateCollectionDatasetSyncConfigParams = {
  datasetName: string;
  collectionId: number;
  labelTypeId: number;
};

function addBodyErrorToMessage(error: HttpErrorResponse): Observable<never> {
  return throwError(() => {
    if (typeof error.error == 'object') {
      if ('error' in error.error) {
        const newMessage = error.message + ` Err: '${error.error.error}'`;
        (error.message as any) = newMessage;
      }
    }
    return error;
  });
}

@Injectable()
export class MlActionService {
  private _collectionUpdated$ = new Subject<void>();
  collectionUpdated$ = this._collectionUpdated$.asObservable();

  constructor(private mlBackendService: MlBackendService) {}

  notifyCollectionUpdated() {
    this._collectionUpdated$.next();
  }

  async createCollections(collectionName: string): Promise<CollectionDto> {
    const url = `/collections`;

    const collection = await firstValueFrom(
      this.mlBackendService.post(
        url,
        { name: collectionName },
        addBodyErrorToMessage,
      ),
    );
    this.notifyCollectionUpdated();
    return collection;
  }

  deleteSegmentsAiSyncConfig(datasetConfigId: number): Promise<void> {
    const url = `/labeling-providers/segments-ai/sync-configs/${datasetConfigId}`;

    return firstValueFrom(
      this.mlBackendService.delete(url, addBodyErrorToMessage),
    );
  }

  createSegmentsAiSyncConfig(
    createCollectionDatasetSyncConfigParams: CreateCollectionDatasetSyncConfigParams,
  ) {
    const url = `/labeling-providers/segments-ai/sync-configs`;

    return firstValueFrom(
      this.mlBackendService.post(
        url,
        createCollectionDatasetSyncConfigParams,
        addBodyErrorToMessage,
      ),
    );
  }

  addLabelType(name: string): Promise<LabelTypeDto> {
    const url = `/label-types`;

    return firstValueFrom(
      this.mlBackendService.post(url, { name }, addBodyErrorToMessage),
    );
  }

  async addFrameToCollection(
    collectionId: number,
    frameId: number,
  ): Promise<void> {
    const url = `/collections/${collectionId}/frames/${frameId}`;
    await firstValueFrom(
      this.mlBackendService.post(url, {}, addBodyErrorToMessage),
    );
    this.notifyCollectionUpdated();
  }

  async addLockToCollection(collectionId: number): Promise<void> {
    const url = `/collections/${collectionId}/lock`;
    await firstValueFrom(
      this.mlBackendService.post(url, {}, addBodyErrorToMessage),
    );
    this.notifyCollectionUpdated();
  }

  async deleteLockFromCollection(collectionId: number): Promise<void> {
    const url = `/collections/${collectionId}/lock`;
    await firstValueFrom(
      this.mlBackendService.delete(url, addBodyErrorToMessage),
    );
    this.notifyCollectionUpdated();
  }

  async addMetadataToAsset(
    assetType: 'frame' | 'snippet',
    assetId: number,
    metadata: any,
  ) {
    const url = `/metadata/${assetType}/${assetId}`;

    await firstValueFrom(
      this.mlBackendService.post(url, metadata, addBodyErrorToMessage),
    );
  }
}
