<h1 mat-dialog-title>Visibility</h1>
<div mat-dialog-content>
  <mat-slide-toggle
    class="visibility-row"
    *ngFor="let visibilityEntry of visibility.visibilityEntries"
    [(ngModel)]="visibilityEntry.visible"
    >{{ visibilityEntry.displayName }}
  </mat-slide-toggle>
  <mat-divider style="margin-top: 5px; margin-bottom: 5px"></mat-divider>
  <div *ngFor="let slippyTilesName of slippyTilesNames">
    <mat-slider min="0" max="1" step="0.1">
      <input
        matSliderThumb
        [value]="visibility.slippyTilesOpacities[slippyTilesName]"
        (valueChange)="
          visibility.slippyTilesOpacities[slippyTilesName] = $event
        "
      />
    </mat-slider>
    <span>{{ slippyTilesName }} Layer Opacity</span>
  </div>
  <mat-divider style="margin-top: 5px; margin-bottom: 5px"></mat-divider>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="visibility">Apply</button>
</div>
