import * as v from 'valibot';
import {
  IdString,
  IsoDateTime,
  NonEmptyString,
  PositiveInteger,
} from './common';
import { MapElement } from './map-element';

export const MapChangesetInfo = v.object({
  id: IdString,
  createdAt: IsoDateTime,
  updatedAt: IsoDateTime,
  authorIds: v.array(IdString),
  title: v.string(),
  description: v.string(),
  committedAt: v.optional(IsoDateTime),
  committedAsVersion: v.optional(PositiveInteger),
  basedOnVersion: v.optional(PositiveInteger),
});
export type MapChangesetInfo = v.InferOutput<typeof MapChangesetInfo>;

export const MapChangeset = v.object({
  ...MapChangesetInfo.entries,
  changedMapElements: v.tupleWithRest([MapElement], MapElement),
});
export type MapChangeset = v.InferOutput<typeof MapChangeset>;

export const CreateOrReplaceMapChangeset = v.object({
  title: NonEmptyString,
  description: NonEmptyString,
  changedMapElements: v.tupleWithRest([MapElement], MapElement),
  basedOnVersion: v.optional(PositiveInteger),
});
export type CreateOrReplaceMapChangeset = v.InferOutput<
  typeof CreateOrReplaceMapChangeset
>;

export const MapChangesetConflict = v.object({
  changesetMapElement: MapElement,
  latestMapElement: MapElement,
});
export type MapChangesetConflict = v.InferOutput<typeof MapChangesetConflict>;
