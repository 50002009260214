<div *ngIf="operation$ | async as operation" class="main-container">
  <mat-toolbar>
    <span>{{ operationDisplayName$ | async }}</span>
    <div>
      <button
        *ngIf="canCreateOrders$ | async"
        mat-raised-button
        (click)="openCreateOrderDialog()"
        class="header-buttons"
        [disabled]="!(isReadyForOrders$ | async)"
      >
        <span *ngIf="isReadyForOrders$ | async">Add Order</span>
        <span *ngIf="!(isReadyForOrders$ | async)">No Active Robot</span>
      </button>
    </div>
  </mat-toolbar>
  <div class="grow-container-area">
    <div class="vertical-container">
      <as-split direction="vertical">
        <as-split-area [size]="60" [minSize]="30">
          <div class="mobile-map-container">
            <app-robot-order-map
              [robots]="robots$ | async"
              [orders]="orders$ | async"
              [operation]="operation"
              [focusItemId]="highlightItemId"
              (zoomPanChanged)="onZoomPanChange($event)"
            ></app-robot-order-map>
          </div>
        </as-split-area>
        <as-split-area [size]="60" [minSize]="15">
          <div class="mobile-bottom-container">
            <mat-tab-group style="width: 100%; height: 100%">
              <mat-tab style="width: 100%; height: 100%" label="Orders">
                <div class="items-container">
                  <div
                    *ngFor="
                      let order of orderCards$ | async;
                      trackBy: compareById
                    "
                    class="item"
                    (click)="highlightItem(order.order.id)"
                  >
                    <div class="item-content">
                      <div class="order-created-at">
                        Created {{ order.createdAt | prettyTime }}
                      </div>
                      <div class="order-item-header">
                        @for (
                          handover of order.handovers;
                          track handover.text
                        ) {
                          <div class="current-handover-icon-border-container">
                            @if (handover.isCurrent) {
                              <mat-icon>arrow_right</mat-icon>
                            } @else {
                              <div class="order-dot-icon"></div>
                            }
                          </div>
                          <div class="text-container">
                            {{ handover.text }}
                          </div>

                          <div class="vertical-line-area">
                            <div class="vertical-line"></div>
                          </div>
                          <div></div>
                        }
                      </div>
                      <div class="order-item-body">
                        <div>
                          <div class="order-item-text">
                            <div class="robot-text-item">
                              <img [src]="order.robotIcon" class="robot-icon" />
                              {{ order.robotName }}
                            </div>
                            is {{ order.status }}
                          </div>
                        </div>
                        <div class="actions-overlay"></div>
                      </div>
                    </div>
                    <button
                      class="button-position"
                      [class.highlighted-item]="
                        highlightItemId === order.order.id
                      "
                      (click)="
                        cancelOrder(order.order); $event.stopPropagation()
                      "
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Robots">
                <div class="items-container">
                  <div
                    *ngFor="
                      let robot of robotCards$ | async;
                      trackBy: compareById
                    "
                    class="robot-item"
                    (click)="highlightItem(robot.robot.id)"
                  >
                    <div class="robot-card-robot-item">
                      <div class="robot-item-robot-card">
                        <img [src]="robot.icon" class="robot-icon-big" />
                        {{ robot.name }}
                      </div>

                      <app-battery-status
                        class="battery-status"
                        [batteryPercentage]="robot.battery"
                        [charging]="robot.isCharging"
                      ></app-battery-status>

                      <mat-icon (click)="openCompartmentDialog(robot.robot)">{{
                        robot.lockIcon
                      }}</mat-icon>
                    </div>

                    <div class="robot-item-header">
                      {{ robot.robotGoal }}
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </as-split-area>
      </as-split>
    </div>
  </div>
</div>
