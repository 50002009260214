import * as v from 'valibot';
import { PointGeometry } from '../geometry';
import { mapElementType, ElementType } from './base';
import {
  AprilTagProperties,
  HandoverLocationProperties,
  InfrastructureProperties,
  PoleProperties,
} from './properties';

export const AprilTag = mapElementType(
  ElementType.APRIL_TAG,
  AprilTagProperties,
  PointGeometry,
);
export type AprilTag = v.InferOutput<typeof AprilTag>;

export const HandoverLocation = mapElementType(
  ElementType.HANDOVER_LOCATION,
  HandoverLocationProperties,
  PointGeometry,
);
export type HandoverLocation = v.InferOutput<typeof HandoverLocation>;

export const Infrastructure = mapElementType(
  ElementType.INFRASTRUCTURE,
  InfrastructureProperties,
  PointGeometry,
);
export type Infrastructure = v.InferOutput<typeof Infrastructure>;

export const Mutex = mapElementType(
  ElementType.MUTEX,
  v.nullish(v.object({})),
  PointGeometry,
);
export type Mutex = v.InferOutput<typeof Mutex>;

export const NodeElement = mapElementType(
  ElementType.NODE,
  v.nullish(v.object({})),
  PointGeometry,
);
export type NodeElement = v.InferOutput<typeof NodeElement>;

export const Pole = mapElementType(
  ElementType.POLE,
  PoleProperties,
  PointGeometry,
);
export type Pole = v.InferOutput<typeof Pole>;

export const TrafficLight = mapElementType(
  ElementType.TRAFFIC_LIGHT,
  v.nullish(v.object({})),
  PointGeometry,
);
export type TrafficLight = v.InferOutput<typeof TrafficLight>;
