import {
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MapElementManager } from './map-elements/map-element-manager';
import {
  Observable,
  fromEvent,
  Subject,
  merge,
  firstValueFrom,
  debounceTime,
  lastValueFrom,
} from 'rxjs';
import { MapService } from '../core/map.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectOperationDialogComponent } from '../core/select-operation-dialog/select-operation-dialog.component';
import {
  ElementType,
  CrossingType,
  MapChangeset,
  CreateOrReplaceMapChangeset,
  PolygonGeometry,
  RobotEdgeProperties,
  MapElement,
  HandoverLocation,
  Infrastructure,
  RobotEdge,
} from '@cartken/map-types';
import { ModeManager, MapEditorMode } from './modes/mode-manager';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { computeCachedRoadEdges } from './map-elements/computeCachedRoadEdges';
import { OperationsService } from '../core/operations-service';
import { Operation } from '../operations/operation';
import { RoutingMode } from './modes/routing-mode';
import { RoutesService } from '../core/route-service';
import { boundsFromCoordinates } from './map-elements/bounding-box-helpers';
import {
  VisibilityDialogComponent,
  VisibilityDialogInput,
  VisibilityEntry,
} from './dialogs/visibility-dialog.component';
import { ConfirmationDialog } from '../../app/core/confirmation-dialog/confirmation-dialog.component';
import { ChangeMapVersionDialogComponent } from './dialogs/change-map-version-dialog.component';
import { LoadChangesetDialogComponent } from './dialogs/load-changeset-dialog.component';
import { SaveChangesetDialogComponent } from './dialogs/save-changeset-dialog.component';
import { toGoogleLatLng } from '../../utils/geo-tools';
import { RebaseMode } from './modes/rebase-mode';
import { visiblePageTimer } from '../../utils/page-visibility';
import { ViewLocationDialogComponent } from './dialogs/view-location-dialog.component';
import { VisualizationManager } from './visualization/visualization-manager';
import { MapStyle } from './visualization/base-map';
import { LatLng, LatLngBounds, computeArea } from 'spherical-geometry-js';
import { InfrastructureType } from '@cartken/map-types';
import { isBlockedUntilExpired } from './visualization/utils';
import { CreateCustomFieldMapElementDialogComponent } from './dialogs/create-custom-field-map-element-dialog.component';
import { hasAtLeastOneElement } from '../../utils/typeGuards';

export type MapElementProperties =
  | 'noProperties'
  | 'defaultProperties'
  | 'edgeProperties'
  | 'operationRegionProperties';

function activeElementIsTextElement() {
  return ['input', 'textarea'].includes(
    document.activeElement?.tagName.toLowerCase() ?? '',
  );
}

interface BlockedFor {
  months: number | undefined;
  days: number | undefined;
  hours: number | undefined;
}

@Component({
  selector: 'app-map-editor',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.sass'],
})
export class MapEditorComponent implements OnDestroy, AfterViewInit {
  @ViewChild('noProperties')
  private noPropertiesTemplate!: TemplateRef<any>;
  @ViewChild('defaultProperties')
  private defaultPropertiesTemplate!: TemplateRef<any>;
  @ViewChild('edgeProperties')
  private edgePropertiesTemplate!: TemplateRef<any>;
  @ViewChild('operationRegionProperties')
  private operationRegionPropertiesTemplate!: TemplateRef<any>;

  @ViewChild('aprilTagProperties')
  private aprilTagPropertiesTemplate!: TemplateRef<any>;
  @ViewChild('poleProperties')
  private polePropertiesTemplate!: TemplateRef<any>;
  @ViewChild('handoverLocationProperties')
  private handoverLocationPropertiesTemplate!: TemplateRef<any>;
  @ViewChild('infrastructureProperties')
  private infrastructurePropertiesTemplate!: TemplateRef<any>;

  @ViewChild('fromLocation') set setFromLocation(fromLocation: ElementRef) {
    if (fromLocation) {
      // initially setter gets called with undefined
      this.modeManager.modes.routing.setFromLocationElement(fromLocation);
    }
  }

  @ViewChild('toLocation') set setToLocation(toLocation: ElementRef) {
    if (toLocation) {
      // initially setter gets called with undefined
      this.modeManager.modes.routing.setToLocationElement(toLocation);
    }
  }

  @ViewChild('mapContainer')
  mapContainerElement!: ElementRef;

  private destroy$ = new Subject<void>();

  private changesetUpdated$ = new Subject<void>();

  mapElementManager!: MapElementManager;
  visualizationManager!: VisualizationManager;
  modeManager!: ModeManager;
  rebaseMode?: RebaseMode;
  editMode: MapEditorMode = 'select';
  lookingUpAddress = false;
  undoRedoAvailable = true;

  cachedRoadEdgeProgress = 100;
  minDisplayAltitude = 0;
  maxDisplayAltitude = 100;

  selectedMapElement$ = new Observable<MapElement | undefined>();
  propertiesTemplate!: TemplateRef<any>;
  currentChangeset?: MapChangeset;

  mapElementVisibility: VisibilityEntry[] = [
    {
      displayName: 'Cached Road Edges',
      elementTypes: [ElementType.CACHED_ROAD_EDGE],
      visible: false,
    },
  ];

  infrastructureTypes = Object.values(InfrastructureType);

  edgeTypes = [
    {
      displayName: 'Robot Edge',
      elementType: ElementType.ROBOT_EDGE,
      selectable: true,
    },
    {
      displayName: 'Robot Queue Edge',
      elementType: ElementType.ROBOT_QUEUE_EDGE,
      selectable: true,
    },
    {
      displayName: 'Infrastructure Edge',
      elementType: ElementType.INFRASTRUCTURE_EDGE,
      selectable: true,
    },
    {
      displayName: 'Infrastructure Waiting Edge',
      elementType: ElementType.INFRASTRUCTURE_WAITING_EDGE,
      selectable: true,
    },
    {
      displayName: 'Movable Platfrom Edge',
      elementType: ElementType.MOVABLE_PLATFORM_EDGE,
      selectable: true,
    },
    {
      displayName: 'Road Edge',
      elementType: ElementType.ROAD_EDGE,
      selectable: true,
    },
    {
      displayName: 'Cached Road Edge',
      elementType: ElementType.CACHED_ROAD_EDGE,
      selectable: false,
    },
  ];

  crossingTypes = [
    {
      displayName: 'Crosses Residential Road',
      value: CrossingType.RESIDENTIAL_ROAD,
    },
    {
      displayName: 'Crosses Single Lane Road',
      value: CrossingType.SINGLE_LANE_ROAD,
    },
    {
      displayName: 'Crosses Multi Lane Road',
      value: CrossingType.MULTI_LANE_ROAD,
    },
    { displayName: 'Crosses Bike Path', value: CrossingType.BIKE_PATH },
    { displayName: 'Crosses Driveway', value: CrossingType.DRIVEWAY },
    { displayName: 'Ascends/Descends Curb', value: CrossingType.CURB },
  ];

  robotQueueEdgeDisplayNameTextInput = '';
  robotQueueEdgeNameTextInput = '';
  blockedFor = <BlockedFor>{};

  constructor(
    private mapService: MapService,
    private operationsService: OperationsService,
    private routesService: RoutesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      'handover-location',
      sanitizer.bypassSecurityTrustResourceUrl('assets/handover-location.svg'),
    );
    iconRegistry.addSvgIcon(
      'pole',
      sanitizer.bypassSecurityTrustResourceUrl('assets/pole.svg'),
    );
    iconRegistry.addSvgIcon(
      'traffic-light',
      sanitizer.bypassSecurityTrustResourceUrl('assets/traffic-light.svg'),
    );
    iconRegistry.addSvgIcon(
      'april-tag',
      sanitizer.bypassSecurityTrustResourceUrl('assets/april-tag.svg'),
    );
    iconRegistry.addSvgIcon(
      'operation-region',
      sanitizer.bypassSecurityTrustResourceUrl('assets/region.svg'),
    );
    iconRegistry.addSvgIcon(
      'edge',
      sanitizer.bypassSecurityTrustResourceUrl('assets/edge.svg'),
    );

    iconRegistry.addSvgIcon(
      'mutex',
      sanitizer.bypassSecurityTrustResourceUrl('assets/mutex.svg'),
    );

    merge(visiblePageTimer(0, 5 * 60 * 1000), this.changesetUpdated$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (!this.currentChangeset) {
          return;
        }
        const latestVersion = (await this.mapService.getMapVersions())
          ?.latestVersion;
        if (latestVersion === undefined) {
          return;
        }
        if ((this.currentChangeset.basedOnVersion ?? 0) < latestVersion) {
          this.snackBar
            .open('Changeset is based on an outdated map version', 'Rebase')
            .onAction()
            .subscribe(() => this.rebaseChangeset());
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
  }

  ngAfterViewInit(): void {
    this.visualizationManager = new VisualizationManager(
      this.mapContainerElement.nativeElement,
    );
    this.mapElementManager = new MapElementManager(
      (boundingCoords, mapVersion) =>
        this.mapService.loadMapElements(boundingCoords, mapVersion),
      (mapVersion) => this.mapService.loadOperationRegions(mapVersion),
    );
    this.visualizationManager.selectedMapElement$.subscribe((m) => {
      this.mapElementManager.selectedMapElement = m;
    });
    this.visualizationManager.onChange$.subscribe(async (changedMapElements) =>
      this.mapElementManager.addChange(changedMapElements),
    );
    this.visualizationManager.boundsChanged$
      .pipe(debounceTime(200))
      .subscribe((bounds) => {
        this.mapElementManager.setBounds(bounds);
      });
    this.mapElementManager.mapElementsById$.subscribe((mapElements) =>
      this.visualizationManager.setMapElements(
        Array.from(mapElements.values()),
      ),
    );
    this.mapElementManager.loadedMapElements$
      .pipe(withLatestFrom(this.visualizationManager.altitudeRange$))
      .subscribe(([_, altitudeRange]) => {
        this.minDisplayAltitude = altitudeRange[0];
        this.maxDisplayAltitude = altitudeRange[1];
        this.visualizationManager.setDisplayAltitudeRange(
          this.minDisplayAltitude,
          this.maxDisplayAltitude,
        );
      });
    this.mapService.getMapVersions().then((versions) => {
      if (versions?.latestVersion !== undefined) {
        this.mapElementManager.setMapVersion(versions.latestVersion);
      }
    });
    this.modeManager = new ModeManager(
      this.visualizationManager,
      this.mapElementManager,
      this.routesService,
    );

    this.applyVisibility();

    this.selectedMapElement$ = this.mapElementManager.selectedMapElement$;
    this.selectedMapElement$.subscribe((mapElement) => {
      this.visualizationManager.setSelectedMapElement(mapElement);
      if (!mapElement) {
        this.propertiesTemplate = this.noPropertiesTemplate;
        return;
      }
      switch (mapElement.elementType) {
        case ElementType.ROBOT_EDGE:
        case ElementType.ROBOT_QUEUE_EDGE:
        case ElementType.ROAD_EDGE:
        case ElementType.CACHED_ROAD_EDGE:
        case ElementType.INFRASTRUCTURE_EDGE:
        case ElementType.INFRASTRUCTURE_WAITING_EDGE:
        case ElementType.MOVABLE_PLATFORM_EDGE:
          this.propertiesTemplate = this.edgePropertiesTemplate;
          break;

        case ElementType.OPERATION_REGION:
          this.propertiesTemplate = this.operationRegionPropertiesTemplate;
          break;

        case ElementType.APRIL_TAG:
          this.propertiesTemplate = this.aprilTagPropertiesTemplate;
          break;

        case ElementType.POLE:
          this.propertiesTemplate = this.polePropertiesTemplate;
          break;

        case ElementType.HANDOVER_LOCATION:
          this.propertiesTemplate = this.handoverLocationPropertiesTemplate;
          break;

        case ElementType.INFRASTRUCTURE:
          this.propertiesTemplate = this.infrastructurePropertiesTemplate;
          break;

        default:
          this.propertiesTemplate = this.defaultPropertiesTemplate;
          break;
      }
      if (isBlockedUntilExpired(mapElement)) {
        delete mapElement.properties.blockedAt;
        delete mapElement.properties.blockedUntil;
      }
    });

    fromEvent(window, 'keydown')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        const keyboardEvent = event as KeyboardEvent;

        // Surpress the key binding input fields are active
        if (activeElementIsTextElement()) {
          return;
        }

        switch (keyboardEvent.code) {
          case 'KeyE':
            this.modeManager.setMapEditorMode('createEdge');
            break;
          case 'KeyT':
            this.modeManager.setMapEditorMode('createTrafficLight');
            break;
          case 'KeyA':
            this.modeManager.setMapEditorMode('createAprilTag');
            break;
          case 'KeyH':
            this.modeManager.setMapEditorMode('createHandoverLocation');
            break;
          case 'KeyR':
            this.modeManager.setMapEditorMode('createOperationRegion');
            break;
          case 'KeyM':
            this.modeManager.setMapEditorMode('createMutex');
            break;
          case 'Escape':
            this.modeManager.setMapEditorMode('select');
            break;
          case 'KeyD':
            this.modeManager.setMapEditorMode('delete');
            break;
          case 'KeyZ':
            if (keyboardEvent.ctrlKey && this.undoRedoAvailable) {
              if (keyboardEvent.shiftKey) {
                this.mapElementManager.redoChange();
              } else {
                this.mapElementManager.undoChange();
              }
            }
            break;
        }
      });
    this.changeDetector.detectChanges();
  }

  applyVisibility() {
    const hiddenElementTypes = this.mapElementVisibility
      .filter((entry) => !entry.visible)
      .map((entry) => entry.elementTypes)
      .flat();
    this.visualizationManager.setHiddenMapElementTypes(hiddenElementTypes);
  }

  onVisibilityClick() {
    const dialogRef = this.dialog.open(VisibilityDialogComponent, {
      width: '400px',
      data: {
        visibilityEntries: this.mapElementVisibility,
        slippyTilesOpacities: this.visualizationManager.getLayerOpacities(),
      },
    });

    dialogRef.afterClosed().subscribe((visibility?: VisibilityDialogInput) => {
      if (!visibility) {
        return;
      }
      this.mapElementVisibility = visibility.visibilityEntries;
      this.visualizationManager.setLayerOpacities(
        visibility.slippyTilesOpacities,
      );
      this.applyVisibility();
    });
  }

  onGoogleMapsClick() {
    const zoom = Math.min(
      21,
      Math.round(this.visualizationManager.getZoom() + 1),
    );
    const center = this.visualizationManager.getLatLngBounds().getCenter();
    const baseUrl = 'https://www.google.com/maps/@?api=1&map_action=map&';
    const params = `center=${center.lat()},${center.lng()}&zoom=${zoom}`;
    window.open(baseUrl + params, '_blank')?.focus();
  }

  enableTerrain(enable: boolean) {
    this.visualizationManager.enableTerrain(enable);
  }

  enableAltitudeFlattening(enable: boolean) {
    this.visualizationManager.enableAltitudeFlattening(enable);
  }

  updateDisplayAltitudes() {
    this.visualizationManager.setDisplayAltitudeRange(
      this.minDisplayAltitude,
      this.maxDisplayAltitude,
    );
  }

  setMapStyle(mapStyle: MapStyle) {
    this.visualizationManager.setMapStyle(mapStyle);
  }

  addChange(mapElement: MapElement) {
    this.mapElementManager.addChange([mapElement]);
  }

  async computeCachedRoadEdges() {
    computeCachedRoadEdges(this.mapElementManager, (progress: number) => {
      this.cachedRoadEdgeProgress = progress;
    });
  }

  exportAllCurrentMapElements() {
    this.exportMapElements(
      Array.from(this.mapElementManager.getMapElements().values()),
    );
  }

  exportChangedMapElements() {
    this.exportMapElements(this.mapElementManager.getChanges());
  }

  exportMapElements(mapElements: MapElement[]) {
    const filename = 'map.json';
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(mapElements)),
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  importMapElements(createNewMapElements: boolean) {
    const element = document.createElement('input');
    element.setAttribute('type', 'file');
    element.onchange = () => {
      const file = element.files?.item(0)!;
      reader.readAsBinaryString(file);
    };
    const reader = new FileReader();
    reader.onload = (event) => {
      const mapElements = JSON.parse(event.target!.result as string);
      this.mapElementManager.importMapElements(
        mapElements,
        createNewMapElements,
      );
    };
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  async viewOperationRegion() {
    const dialogRef = this.dialog.open(SelectOperationDialogComponent, {
      width: '40rem',
      data: {
        operations: await firstValueFrom(
          this.operationsService.getOperations(),
        ),
      },
    });

    dialogRef.afterClosed().subscribe((operation?: Operation) => {
      const bounds = boundsFromCoordinates(
        operation?.operationRegion?.coordinates[0],
      );
      if (bounds) {
        this.visualizationManager.fitBounds(bounds);
        this.modeManager.modes.routing.limitToOperationId = operation?.id;
      }
    });
  }

  async viewLocation() {
    const nearbyQueueLocations = [
      ...this.mapElementManager.getMapElements().values(),
    ].filter(
      (el) => !el.deleted && el.elementType === ElementType.ROBOT_QUEUE_EDGE,
    );

    const dialogRef = this.dialog.open(ViewLocationDialogComponent, {
      data: {
        currentMapVersion: this.mapElementManager.mapVersion(),
        nearbyQueueLocations,
      },
    });

    dialogRef.afterClosed().subscribe((latLng?: google.maps.LatLng) => {
      if (latLng) {
        const bounds = new LatLngBounds(latLng, latLng);
        this.visualizationManager.fitBounds(bounds);
      }
    });
  }

  async changeMapVersion() {
    this.dialog
      .open(ChangeMapVersionDialogComponent, {
        data: {
          currentMapVersion: this.mapElementManager.mapVersion(),
          changesets: await this.mapService.loadMapChangesetInfos(),
        },
        minWidth: '500px',
        maxWidth: '80vw',
        maxHeight: '90vh',
      })
      .afterClosed()
      .subscribe((mapVersion) => {
        if (mapVersion !== undefined) {
          this.mapElementManager.setMapVersion(mapVersion);
        }
      });
  }

  deployCurrentMapVersion() {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Really deploy map version ${this.mapElementManager.mapVersion()}?`,
        },
      })
      .afterClosed()
      .subscribe(async (confirmed) => {
        if (confirmed) {
          const mapVersion = this.mapElementManager.mapVersion();
          if (mapVersion === undefined) {
            return;
          }
          const startTime = Date.now();
          await this.mapService.deployMapVersion(mapVersion);
          console.log(`Deployed in ${Date.now() - startTime}ms`);
        }
      });
  }

  async loadChangeset() {
    this.dialog
      .open(LoadChangesetDialogComponent, {
        data: {
          changesets: await this.mapService.loadMapChangesetInfos(),
        },
        minWidth: '500px',
        maxWidth: '80vw',
        maxHeight: '90vh',
      })
      .afterClosed()
      .subscribe(async (changesetId) => {
        if (changesetId === undefined) {
          return;
        }
        const currentChangeset =
          await this.mapService.loadMapChangeset(changesetId);
        this.currentChangeset = currentChangeset;
        this.mapElementManager.setMapVersion(currentChangeset.basedOnVersion);
        this.mapElementManager.replaceChanges(
          currentChangeset.changedMapElements,
        );
        this.changesetUpdated$.next(undefined);
      });
  }

  now(): Date {
    return new Date();
  }

  saveChangeset() {
    const changedMapElements = this.mapElementManager.getChanges();
    if (!hasAtLeastOneElement(changedMapElements)) {
      return;
    }

    this.dialog
      .open(SaveChangesetDialogComponent, {
        data: {
          title: this.currentChangeset?.title,
          description: this.currentChangeset?.description,
        },
        minWidth: '500px',
        maxWidth: '80vw',
        maxHeight: '90vh',
      })
      .afterClosed()
      .subscribe(
        async (changesetInfo?: { title: string; description: string }) => {
          if (changesetInfo) {
            const updatedChangeset: CreateOrReplaceMapChangeset = {
              ...changesetInfo,
              changedMapElements,
              basedOnVersion: this.mapElementManager.mapVersion(),
            };
            if (this.currentChangeset) {
              this.currentChangeset = await this.mapService.replaceMapChangeset(
                this.currentChangeset.id,
                updatedChangeset,
              );
            } else {
              this.currentChangeset =
                await this.mapService.createMapChangeset(updatedChangeset);
            }
            this.mapElementManager.replaceChanges(changedMapElements);
            this.changesetUpdated$.next(undefined);
          }
        },
      );
  }

  async rebaseChangeset() {
    if (!this.currentChangeset) {
      return;
    }
    this.modeManager.setMapEditorMode('rebase');
    this.modeManager.enableMapEditorModeChanges(false);
    this.undoRedoAvailable = false;

    this.rebaseMode = this.modeManager.modes.rebase;
    this.rebaseMode.setConflicts(
      await this.mapService.getMapChangesetConflicts(this.currentChangeset.id),
    );
  }

  exitRebaseMode() {
    this.modeManager.enableMapEditorModeChanges(true);
    this.modeManager.setMapEditorMode('select');
    this.rebaseMode = undefined;
    this.undoRedoAvailable = true;
  }

  async saveRebaseToChangesetAndExit() {
    if (
      !this.currentChangeset ||
      !this.rebaseMode ||
      !this.rebaseMode.allConflictsResolved()
    ) {
      return;
    }

    this.mapElementManager.addChange(this.rebaseMode.getResolvedConflicts());
    const latestVersion =
      (await this.mapService.getMapVersions())?.latestVersion ?? 0;
    this.currentChangeset.basedOnVersion = latestVersion;
    this.mapElementManager.setMapVersion(latestVersion);
    this.exitRebaseMode();
  }

  async commitChangeset() {
    if (!this.currentChangeset || this.mapElementManager.numChanges() !== 1) {
      return;
    }

    const committedChangeset = await this.mapService.commitMapChangeset(
      this.currentChangeset.id,
    );

    if (committedChangeset?.committedAsVersion !== undefined) {
      this.mapElementManager.replaceChanges([]);
      this.mapElementManager.setMapVersion(
        committedChangeset?.committedAsVersion,
      );
      this.currentChangeset = undefined;
    }
  }

  closeChangeset() {
    this.currentChangeset = undefined;
    this.mapElementManager.replaceChanges([]);
    this.snackBar.dismiss();
  }

  async deleteChangeset() {
    if (!this.currentChangeset) {
      return;
    }
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Really delete current change set '${this.currentChangeset.title}'?`,
        },
      })
      .afterClosed()
      .subscribe(async (confirmed) => {
        if (
          !confirmed ||
          !this.currentChangeset ||
          !(await this.mapService.deleteChangeset(this.currentChangeset.id))
        ) {
          return;
        }
        this.currentChangeset = undefined;
        this.mapElementManager.replaceChanges([]);
        this.snackBar.dismiss();
      });
  }

  getRegionPolygonArea(operationRegionGeometry: PolygonGeometry) {
    const polygon = operationRegionGeometry.coordinates[0];
    if (!polygon) {
      return 0;
    }
    const path = polygon.map((m) => new LatLng(m[1]!, m[0]!));
    return computeArea(path);
  }

  lookupHandoverAddress(address: string, handoverLocation: HandoverLocation) {
    const geocodingService = new google.maps.Geocoder();
    this.lookingUpAddress = true;
    geocodingService.geocode(
      {
        address,
        location: toGoogleLatLng(handoverLocation.geometry.coordinates)!,
      },
      (
        results: google.maps.GeocoderResult[] | null,
        status: google.maps.GeocoderStatus,
      ) => {
        this.lookingUpAddress = false;
        const firstResult = results?.[0];
        if (
          status !== google.maps.GeocoderStatus.OK ||
          firstResult === undefined
        ) {
          this.snackBar.open('Did not find address', '', {
            duration: 2000,
          });
          return;
        }
        this.updateHandoverLocation(handoverLocation, firstResult);
      },
    );
  }

  private updateHandoverLocation(
    handoverLocation: HandoverLocation,
    geocoderResult: google.maps.GeocoderResult,
  ) {
    const streetName = geocoderResult.address_components.find((component) =>
      component.types.includes('route'),
    )?.long_name;
    const streetNumber = geocoderResult.address_components.find((component) =>
      component.types.includes('street_number'),
    )?.long_name;
    const subpremise = geocoderResult.address_components.find((component) =>
      component.types.includes('subpremise'),
    )?.long_name;

    if (!streetName || !streetNumber) {
      this.snackBar.open('Address does not contain street number or name', '', {
        duration: 2000,
      });
      return;
    }

    const props = handoverLocation.properties;
    props.streetName = streetName;
    props.streetNumber = streetNumber;
    props.subpremise = subpremise;
    this.addChange(handoverLocation);
  }

  deleteCustomFieldItem(mapElement: MapElement, deletedField: string) {
    if (mapElement.properties && 'customFields' in mapElement.properties) {
      delete mapElement.properties.customFields[deletedField];
    }
    this.addChange(mapElement);
  }

  async addCustomFieldItem(infra: Infrastructure) {
    const { key, value } = await lastValueFrom(
      this.dialog
        .open(CreateCustomFieldMapElementDialogComponent)
        .afterClosed(),
    );
    infra.properties.customFields[key] = value;
    this.addChange(infra);
  }

  trackSlotPrioritiesByIndex(index: number, slotPriority: number) {
    return index;
  }

  updateBlockage(edge: RobotEdge, blocked: boolean) {
    const edgeProperties = edge.properties;
    if (blocked) {
      edgeProperties.blockedAt = this.now();
    } else {
      delete edgeProperties.blockedAt;
      delete edgeProperties.blockedUntil;
    }
    this.addChange(edge);
  }

  updateBlockedUntil(edge: RobotEdge) {
    const now = this.now();
    const edgeProperties = edge.properties;
    edgeProperties.blockedAt ??= now;
    if (
      this.blockedFor.months ||
      this.blockedFor.days ||
      this.blockedFor.hours
    ) {
      const futureDate = new Date(now);
      futureDate.setMonth(
        futureDate.getMonth() + (this.blockedFor.months ?? 0),
      );
      futureDate.setDate(futureDate.getDate() + (this.blockedFor.days ?? 0));
      futureDate.setHours(futureDate.getHours() + (this.blockedFor.hours ?? 0));
      edgeProperties.blockedUntil = futureDate;
    } else {
      delete edgeProperties.blockedUntil;
    }
    this.addChange(edge);
  }
}
