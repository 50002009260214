import { combineLatest, map, Observable, takeUntil } from 'rxjs';
import { Finalizable } from '../../../../utils/finalizable';
import { BackendStatePolling } from '../backend/backend-state-polling';
import { RobotActionRequestManager } from './action-request-manager';
import { RtcSendDataChannels } from '../webrtc/rtc-send-data-channels';
import { UserSessionInteractionEventName } from '../../user-session/user-session-interaction-events';
import { UserSessionEventTrackingService } from '../../user-session/user-session-event-tracking.service';
import { InControlManager } from './in-control-manager';

export type UnsupervisedAutonomyUIState = {
  available: boolean;
  active: boolean;
  remainingDistance: number;
};

const HANDLE_UNSUPERVISED_AUTONOMY_ABORT_ACTION_TYPE_ID =
  'handle-unsupervised-autonomy-abort';

const DEBOUNCE_UNSUPERVISED_AUTONOMY_ABORT_REPORT_HANDLED = 5000;

function abortDescriptionEnumToText(description: string): string {
  return description
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLocaleLowerCase();
}

export class UnsupervisedAutonomy extends Finalizable {
  // prevent duplicate notifications
  private abortTimestamp: Date | null = null;
  private isInControl: boolean = false;

  unsupervisedAutonomyState$: Observable<UnsupervisedAutonomyUIState>;

  constructor(
    robotId: string,
    backendStatePolling: BackendStatePolling,
    robotActionRequestManager: RobotActionRequestManager,
    rtcSendDataChannels: RtcSendDataChannels,
    inControlManager: InControlManager,
    userSessionEventTrackingService: UserSessionEventTrackingService,
  ) {
    super();

    const unsupervisedAutonomyState$ = backendStatePolling.robotState$.pipe(
      takeUntil(this.finalized$),
      map((robotState) => {
        return robotState.unsupervisedAutonomyState;
      }),
    );

    combineLatest([
      unsupervisedAutonomyState$,
      inControlManager.isInControl$,
    ]).subscribe(([state, isInControl]) => {
      const isStateChanged =
        state?.abortStamp !== this.abortTimestamp ||
        this.isInControl !== isInControl;

      if (!isStateChanged) {
        return;
      }

      const abortReport = state?.abortReport;

      if (!abortReport) {
        robotActionRequestManager.removeNotification(
          HANDLE_UNSUPERVISED_AUTONOMY_ABORT_ACTION_TYPE_ID,
        );
        return;
      }

      this.isInControl = isInControl;

      const abortDescription = abortDescriptionEnumToText(abortReport);
      const actionDescription = `Unsupervised autonomy aborted: ${abortDescription}`;
      this.abortTimestamp = state?.abortStamp ?? null;

      if (isInControl) {
        robotActionRequestManager.notify({
          actionIdType: HANDLE_UNSUPERVISED_AUTONOMY_ABORT_ACTION_TYPE_ID,
          actionDescription: actionDescription,
          actionButton: 'Mark handled',
          onClick: async () => {
            userSessionEventTrackingService.trackInteractionEvent(
              UserSessionInteractionEventName.UNSUPERVISED_AUTONOMY_ABORT_HANDLED,
              {
                robotId,
              },
            );
            rtcSendDataChannels.sendReliable({
              label: 'unsupervisedAutonomyAbortReportHandled',
              payload: {},
            });

            setTimeout(() => {
              if (this.abortTimestamp === state?.abortStamp) {
                this.abortTimestamp = null;
              }
            }, DEBOUNCE_UNSUPERVISED_AUTONOMY_ABORT_REPORT_HANDLED);
          },
        });
      } else {
        robotActionRequestManager.notify({
          actionIdType: HANDLE_UNSUPERVISED_AUTONOMY_ABORT_ACTION_TYPE_ID,
          actionDescription: `${actionDescription} (take control to handle report)`,
        });
      }
    });

    this.unsupervisedAutonomyState$ = unsupervisedAutonomyState$.pipe(
      map((state) => {
        return {
          available: state?.available ?? false,
          active: state?.active ?? false,
          remainingDistance: state?.remainingDistance ?? 0,
        };
      }),
    );
  }

  protected override async onFinalize(): Promise<void> {
    // Nothing
  }
}
