import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElementType } from '@cartken/map-types';
import { LayerName } from '../visualization/visualization-styles';

export interface VisibilityEntry {
  displayName: string;
  elementTypes: ElementType[];
  visible: boolean;
}

export interface LocalizationMapVisibility {
  visible: boolean;
  opacity: number;
}

export interface VisibilityDialogInput {
  visibilityEntries: VisibilityEntry[];
  slippyTilesOpacities: Record<LayerName, number>;
}

@Component({
  selector: 'visibility-dialog',
  styleUrls: ['./visibility-dialog.component.sass'],
  templateUrl: './visibility-dialog.component.html',
})
export class VisibilityDialogComponent {
  slippyTilesNames = Object.values(LayerName);

  constructor(
    public dialogRef: MatDialogRef<VisibilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public visibility: VisibilityDialogInput,
  ) {}
}
