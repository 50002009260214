@if (clockingService.clockedInAt$ | async; as clockedInAt) {
  <button
    mat-fab
    extended
    color="primary"
    (click)="clockingService.clockOut()"
    matTooltip="Clock out"
  >
    <mat-icon>timer</mat-icon>
    <span class="pulsing-text">{{ clockedInAt | hoursSince }}</span>
  </button>
} @else {
  <button
    mat-fab
    color="primary"
    (click)="clockingService.clockIn()"
    matTooltip="Clock in"
  >
    <mat-icon>timer</mat-icon>
  </button>
}
