import * as v from 'valibot';

export const Integer = v.pipe(v.number(), v.integer());
export const PositiveNumber = v.pipe(v.number(), v.minValue(0));
export const PositiveInteger = v.pipe(Integer, v.minValue(0));
export const NonEmptyString = v.pipe(v.string(), v.nonEmpty());
export const Latitude = v.pipe(v.number(), v.minValue(-90), v.maxValue(90));
export const Longitude = v.pipe(v.number(), v.minValue(-180), v.maxValue(180));

export const IdInteger = Integer;
export const IdString = NonEmptyString;

export function uniqueArray<
  S extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>,
>(schema: S) {
  return v.pipe(
    v.array(schema),
    v.check((v) => new Set(v).size === v.length, 'Expected unique items'),
  );
}

export const IsoDateTime = v.pipe(
  v.union([v.string(), v.date()]),
  v.transform((str) => new Date(str)),
);
