import { combineLatestWith, map, share, takeUntil } from 'rxjs/operators';
import { BackendStatePolling } from './backend-state-polling';
import { Finalizable } from '../../../../utils/finalizable';
import {
  RobotActionInfo,
  RobotActionRequestManager,
} from '../local-logic/action-request-manager';
import { VoidAsyncFunction } from '../../../../utils/type-utils';
import { UserSessionService } from '../../user-session/user-session.service';
import { InControlManager } from '../local-logic/in-control-manager';

const FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS = 5 * 1000;
const MIN_DISTANCE_TO_ACTIVATE = 50;
const REPEAT_AFTER_MILLIS = 10 * 1000;

const notificationDate: RobotActionInfo = {
  actionIdType: 'unsupervised-autonomy-activation-action',
  actionDescription: 'Activating unsupervised autonomy. Will be skipped in',
  actionButton: 'Keep Robot',
};

export class UnsupervisedAutonomyActivation extends Finalizable {
  private availableSince: number | null = null;
  private isNotificationUp = false;
  private debounceUntilMillis: number | null = null;

  constructor(
    private readonly robotId: string,
    private readonly backendStatePolling: BackendStatePolling,
    private readonly userSessionService: UserSessionService,
    private readonly robotActionManager: RobotActionRequestManager,
    private readonly inControlManager: InControlManager,
    finalize: VoidAsyncFunction,
  ) {
    super();
    const isAvailableForMillis$ = this.backendStatePolling.robotState$.pipe(
      takeUntil(this.finalized$),
      combineLatestWith(this.inControlManager.isInControl$),
      map(([robot, isInControl]) => {
        if (!isInControl) {
          return null;
        }
        const state = robot.unsupervisedAutonomyState;
        if (!state || state.active) {
          this.availableSince = null;
          return null;
        }
        if (
          !state.available ||
          (state.remainingDistance ?? 0) < MIN_DISTANCE_TO_ACTIVATE
        ) {
          this.availableSince = null;
          return null;
        }
        if (this.availableSince === null) {
          this.availableSince = Date.now();
        }
        return Date.now() - this.availableSince;
      }),
      share(),
    );

    isAvailableForMillis$.subscribe(
      (unsupervisedAutonomyAvailableForMillis) => {
        if (this.debounceUntilMillis && this.debounceUntilMillis > Date.now()) {
          return;
        }
        if (!unsupervisedAutonomyAvailableForMillis) {
          this.robotActionManager.removeNotification(
            notificationDate.actionIdType,
          );
          return;
        }

        const now = Date.now();
        if (!this.isNotificationUp) {
          this.isNotificationUp = true;
          this.robotActionManager.notify({
            ...notificationDate,
            expiresAt: new Date(
              now + FINALIZE_ROBOT_COMMUNICATION_THRESHOLD_MILLIS,
            ),
            onClick: () => {
              this.availableSince = null;
              this.debounceUntilMillis = Date.now() + REPEAT_AFTER_MILLIS;
              this.isNotificationUp = false;
            },
            onExpire: async () => {
              this.userSessionService.unassignRobots([this.robotId], true);
              await finalize();
            },
          });
        }
      },
    );
  }
  protected override async onFinalize(): Promise<void> {
    // nothing to do, just implement an interface
  }
}
