import { NgModule } from '@angular/core';
import { DefaultPipe } from './default.pipe';
import { DurationPipe } from './duration.pipe';
import { LengthPipe } from './length.pipe';
import { PrettyTimePipe } from './pretty-time.pipe';
import { HoursSincePipe } from './hours-since.pipe';

@NgModule({
  imports: [],
  declarations: [
    PrettyTimePipe,
    DurationPipe,
    DefaultPipe,
    LengthPipe,
    HoursSincePipe,
  ],
  exports: [
    PrettyTimePipe,
    DurationPipe,
    DefaultPipe,
    LengthPipe,
    HoursSincePipe,
  ],
})
export class PipesModule {}
