import * as v from 'valibot';
import { Latitude, Longitude } from './common';

export type GeoPoint =
  | [lng: number, lat: number]
  | [lng: number, lat: number, alt: number];

export const GeoPoint = v.pipe(
  v.union([
    // ORDER IMPORTANT
    v.tuple([Longitude, Latitude, v.nullish(v.number())]),
    v.tuple([Longitude, Latitude]),
  ]),
  v.transform(([lng, lat, alt]): GeoPoint => {
    if (alt === undefined || alt === null || alt === 0 || !isFinite(alt)) {
      return [lng, lat];
    }
    return [lng, lat, alt];
  }),
);
