import {
  Component,
  Inject,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElementType, MapElement } from '@cartken/map-types';
import { Subject } from 'rxjs';
import { LatLngBounds } from 'spherical-geometry-js';
import { VisualizationManager } from '../../map-editor/visualization/visualization-manager';
import { BlockageManager } from './blockage-manager';
import { BlockageUpdateMode } from './blockage-update-mode';
import { BlockageUpdateDto, MapService } from '../map.service';

export interface ReportBlockageDialogData {
  currentLongitude: number;
  currentLatitude: number;
  currentAltitude: number;
  currentHeading: number;
}

@Component({
  selector: 'app-report-blockage-dialog',
  templateUrl: './report-blockage-dialog.component.html',
  styleUrls: ['./report-blockage-dialog.component.sass'],
})
export class ReportBlockageDialogComponent implements OnDestroy, AfterViewInit {
  @ViewChild('mapContainer')
  mapContainerElement!: ElementRef;
  data: ReportBlockageDialogData;
  blockageManager = new BlockageManager();
  currentMapElements: MapElement[] = [];
  description = '';

  blockedUntilMonths: number | undefined;
  blockedUntilWeeks: number | undefined;
  blockedUntilDays: number | undefined;
  blockedUntilHours: number | undefined;
  blockedUntilMinutes: number | undefined;

  blockedUntil: Date | undefined;

  waitingForUpdateToFinish = false;

  private destroyed$ = new Subject<void>();

  private visualizationManger!: VisualizationManager;
  constructor(
    public dialogRef: MatDialogRef<ReportBlockageDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: ReportBlockageDialogData,
    private mapService: MapService,
  ) {
    this.data = data;
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  async onUpdateClick() {
    if (!this.description) {
      return;
    }
    const changes = this.blockageManager.getChanges();
    const changedMapElements = changes.map((change) => {
      return <BlockageUpdateDto>{
        mapElementId: change.mapElementId,
        isBlocked: change.isBlocked,
        blockedUntil: change.isBlocked ? this.blockedUntil : undefined,
      };
    });
    this.waitingForUpdateToFinish = true;
    await this.mapService.updateBlockages(changedMapElements, this.description);
    this.dialogRef.close();
  }

  onResetClick() {
    this.blockageManager.reset();
  }

  ngAfterViewInit() {
    this.visualizationManger = new VisualizationManager(
      this.mapContainerElement.nativeElement,
    );
    this.visualizationManger.setMode(
      new BlockageUpdateMode(this.blockageManager, {
        latitude: this.data.currentLatitude,
        longitude: this.data.currentLongitude,
        altitude: this.data.currentAltitude,
        heading: this.data.currentHeading,
      }),
    );

    const bounds = new LatLngBounds(
      {
        latitude: this.data.currentLatitude,
        longitude: this.data.currentLongitude,
      },
      {
        latitude: this.data.currentLatitude,
        longitude: this.data.currentLongitude,
      },
    );
    this.visualizationManger.fitBounds(bounds);
    this.visualizationManger.boundsChanged$.subscribe((bounds) =>
      this.showMapElementsInBounds(bounds),
    );
    this.blockageManager.onChange$.subscribe(() =>
      this.visualizationManger.setMapElements(
        this.blockageManager.applyChanges(this.currentMapElements),
      ),
    );
  }

  async showMapElementsInBounds(bounds: LatLngBounds) {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const neLat = ne.lat();
    const neLng = ne.lng();
    const swLat = sw.lat();
    const swLng = sw.lng();

    const regionPolygon = [
      [swLng, swLat],
      [neLng, swLat],
      [neLng, neLat],
      [swLng, neLat],
      [swLng, swLat],
    ];

    this.currentMapElements = (
      await this.mapService.loadMapElements(regionPolygon)
    ).filter((mapElement) => mapElement.elementType === ElementType.ROBOT_EDGE);
    this.visualizationManger.setMapElements(
      this.blockageManager.applyChanges(this.currentMapElements),
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined);
  }

  updateBlockedUntil() {
    if (
      !this.blockedUntilMonths &&
      !this.blockedUntilWeeks &&
      !this.blockedUntilDays &&
      !this.blockedUntilHours &&
      !this.blockedUntilMinutes
    ) {
      this.blockedUntil = undefined;
    }
    const now = new Date();
    const futureDate = new Date(now);
    futureDate.setMonth(futureDate.getMonth() + (this.blockedUntilMonths ?? 0));
    futureDate.setDate(
      futureDate.getDate() + (this.blockedUntilWeeks ?? 0) * 7,
    );
    futureDate.setDate(futureDate.getDate() + (this.blockedUntilDays ?? 0));
    futureDate.setHours(futureDate.getHours() + (this.blockedUntilHours ?? 0));
    futureDate.setMinutes(
      futureDate.getMinutes() + (this.blockedUntilMinutes ?? 0),
    );
    this.blockedUntil = futureDate;
  }
}
