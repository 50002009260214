<table
  mat-table
  [dataSource]="dataSource"
  [hidden]="dataSource.data.length == 0"
  class="mat-elevation-z8"
  matSort
  matSortActive="start"
  matSortDirection="desc"
  matSortDisableClear
  (matSortChange)="sortData($event)"
>
  <ng-container matColumnDef="robot">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Robot</th>
    <td mat-cell *matCellDef="let element">{{ element.robotName }}</td>
  </ng-container>

  <ng-container matColumnDef="start">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
    <td mat-cell *matCellDef="let element">
      {{ element.start | date: 'short' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="end">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
    <td mat-cell *matCellDef="let element">
      {{ element.end | date: 'short' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="length">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Length</th>
    <td mat-cell *matCellDef="let element">
      {{ element.trajectory_length }} m
    </td>
  </ng-container>

  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button color="primary" mat-mini-fab (click)="downloadContent(element)">
        <mat-icon>download</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="element-row"
    [class.empty-polygon]="row.polyline === undefined"
    (click)="showTrajectory(row)"
  ></tr>
</table>
