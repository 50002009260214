<div class="arrive-at-route-end-container" *ngIf="distanceToEndOfRoute">
  <div *ngIf="distanceToEndOfRoute < 30" class="backgroud-icon-with-distance">
    <mat-icon
      class="confirmation-icon"
      [class.active-confirmation-icon]="
        distanceToEndOfRoute < arrivalDistanceThreshold
      "
      [class.blinking-border]="distanceToEndOfRoute < 5"
      (click)="triggerArrive()"
      >flag</mat-icon
    >
    <div class="distance-text">
      Arriving in {{ distanceToEndOfRoute | number: '1.0-0' }}m
    </div>
    <div class="confirmation-text">Arrive now</div>
  </div>
</div>
