import { CompositeLayer, CompositeLayerProps } from '@deck.gl/core';
import {
  ClickEvent,
  StartDraggingEvent,
  StopDraggingEvent,
  DraggingEvent,
  PointerMoveEvent,
  Pick,
} from './types';
import { GeoPoint } from '@cartken/map-types';

export type InteractiveLayerProps = CompositeLayerProps;

interface InteractiveLayerState {
  // Picked objects at the time the pointer went down
  pointerDownPicks: Pick[] | null;
  // Screen coordinates where the pointer went down
  pointerDownScreenCoords: [number, number] | null;
  // Ground coordinates where the pointer went down
  pointerDownMapCoords: GeoPoint | null;
  eventHandler: (event: any) => void;
}

export abstract class InteractiveLayer<
  ExtraPropsT = Record<string, unknown>,
> extends CompositeLayer<ExtraPropsT & Required<InteractiveLayerProps>> {
  static override layerName = 'InteractiveLayer';

  // Overridable interaction event handlers
  onLeftClick(event: ClickEvent) {
    // default implementation - do nothing
  }

  onRightClick(event: ClickEvent) {
    // default implementation - do nothing
  }

  onStartDragging(event: StartDraggingEvent) {
    // default implementation - do nothing
  }

  onStopDragging(event: StopDraggingEvent) {
    // default implementation - do nothing
  }

  onDragging(event: DraggingEvent) {
    // default implementation - do nothing
  }

  onPointerMove(event: PointerMoveEvent) {
    // default implementation - do nothing
  }

  onLayerKeyUp(event: KeyboardEvent): void {
    // default implementation - do nothing;
  }
  // TODO: implement onCancelDragging (e.g. drag off screen)

  override initializeState() {
    this.setState({
      _interactiveLayerState: {
        // Picked objects at the time the pointer went down
        pointerDownPicks: null,
        // Screen coordinates where the pointer went down
        pointerDownScreenCoords: null,
        // Ground coordinates where the pointer went down
        pointerDownMapCoords: null,

        // Keep track of the mjolnir.js event handler so it can be deregistered
        eventHandler: this._forwardEventToCurrentLayer.bind(this),
      },
    });

    this._addEventHandlers();
  }

  override finalizeState() {
    this._removeEventHandlers();
  }

  private _addEventHandlers() {
    // @ts-expect-error accessing protected props
    const eventManager = this.context.deck?.eventManager;
    const { eventHandler } = this.state[
      '_interactiveLayerState'
    ] as InteractiveLayerState;

    eventManager?.on(
      {
        click: eventHandler,
        contextmenu: eventHandler,
        pointermove: eventHandler,
        panstart: eventHandler,
        panend: eventHandler,
        panmove: eventHandler,
        keyup: eventHandler,
      },
      {
        // give nebula a higher priority so that it can stop propagation to deck.gl's map panning handlers
        priority: 100,
      },
    );
  }

  private _removeEventHandlers() {
    // @ts-expect-error accessing protected props
    const eventManager = this.context.deck?.eventManager;
    const { eventHandler } = this.state[
      '_interactiveLayerState'
    ] as InteractiveLayerState;
    eventManager?.off({
      click: eventHandler,
      contextmenu: eventHandler,
      pointermove: eventHandler,
      panstart: eventHandler,
      panend: eventHandler,
      panmove: eventHandler,
      keyup: eventHandler,
    });
  }

  // A new layer instance is created on every render, so forward the event to the current layer
  // This means that the first layer instance will stick around to be the event listener, but will forward the event
  // to the latest layer instance.
  private _forwardEventToCurrentLayer(event: any) {
    const currentLayer = this.getCurrentLayer();
    if (!currentLayer) {
      return;
    }

    // Use a naming convention to find the event handling function for this event type
    const func = (currentLayer as any)[`_on${event.type}`]?.bind(currentLayer);
    if (!func) {
      console.warn(`no handler for mjolnir.js event ${event.type}`); // eslint-disable-line
      return;
    }
    func(event);
  }

  private _onclick(event: any) {
    const srcEvent = event.srcEvent;
    const screenCoords = this.getScreenCoords(srcEvent) as [number, number];
    const mapCoords = this.getMapCoords(screenCoords);

    const picks = this.getPicks(screenCoords);

    if (event.leftButton) {
      this.onLeftClick({
        mapCoords,
        screenCoords,
        picks,
        sourceEvent: srcEvent,
      });
    } else if (event.rightButton) {
      this.onRightClick({
        mapCoords,
        screenCoords,
        picks,
        sourceEvent: srcEvent,
      });
    }
  }

  private _oncontextmenu(event: any) {
    event.stopPropagation();
  }

  private _onkeyup({ srcEvent }: { srcEvent: KeyboardEvent }) {
    this.onLayerKeyUp(srcEvent);
  }

  private _onpanstart(event: any) {
    const screenCoords = this.getScreenCoords(event.srcEvent) as [
      number,
      number,
    ];
    const mapCoords = this.getMapCoords(screenCoords);
    const picks = this.getPicks(screenCoords);

    this.setState({
      _interactiveLayerState: {
        ...(this.state['_interactiveLayerState'] as InteractiveLayerState),
        pointerDownScreenCoords: screenCoords,
        pointerDownMapCoords: mapCoords,
        pointerDownPicks: picks,
      },
      interactiveState: {
        ...(this.state['interactiveState'] as any),
        isDragging: true,
      },
    });

    this.onStartDragging({
      picks,
      screenCoords,
      mapCoords,
      pointerDownScreenCoords: screenCoords,
      pointerDownMapCoords: mapCoords,
      cancelPan: event.stopImmediatePropagation,
      sourceEvent: event.srcEvent,
    });
  }

  private _onpanmove(event: any) {
    const { srcEvent } = event;
    const screenCoords = this.getScreenCoords(srcEvent) as [number, number];
    const mapCoords = this.getMapCoords(screenCoords);

    const { pointerDownPicks, pointerDownScreenCoords, pointerDownMapCoords } =
      this.state['_interactiveLayerState'] as InteractiveLayerState;

    const picks = this.getPicks(screenCoords);

    this.onDragging({
      screenCoords,
      mapCoords,
      picks,
      pointerDownPicks,
      pointerDownScreenCoords: pointerDownScreenCoords ?? [0, 0],
      pointerDownMapCoords: pointerDownMapCoords ?? [0, 0, 0],
      sourceEvent: srcEvent,
      cancelPan: event.stopImmediatePropagation,
      // another (hacky) approach for cancelling map panning
      // const controller = this.context.deck.viewManager.controllers[
      //   Object.keys(this.context.deck.viewManager.controllers)[0]
      // ];
      // controller._state.isDragging = false;
    });
  }

  private _onpanend({ srcEvent }: any) {
    const screenCoords = this.getScreenCoords(srcEvent) as [number, number];
    const mapCoords = this.getMapCoords(screenCoords);

    const { pointerDownPicks, pointerDownScreenCoords, pointerDownMapCoords } =
      this.state['_interactiveLayerState'] as InteractiveLayerState;

    const picks = this.getPicks(screenCoords);

    this.onStopDragging({
      picks,
      screenCoords,
      mapCoords,
      pointerDownPicks,
      pointerDownScreenCoords: pointerDownScreenCoords ?? [0, 0],
      pointerDownMapCoords: pointerDownMapCoords ?? [0, 0, 0],
      sourceEvent: srcEvent,
    });

    this.setState({
      _interactiveLayerState: {
        ...(this.state['_interactiveLayerState'] as InteractiveLayerState),
        pointerDownScreenCoords: null,
        pointerDownMapCoords: null,
        pointerDownPicks: null,
      },
      interactiveState: {
        ...(this.state['interactiveState'] as any),
        isDragging: false,
      },
    });
  }

  private _onpointermove(event: any) {
    const { srcEvent } = event;
    const screenCoords = this.getScreenCoords(srcEvent) as [number, number];
    // const mapCoords = this.getMapCoords(screenCoords);

    const { pointerDownPicks, pointerDownScreenCoords, pointerDownMapCoords } =
      this.state['_interactiveLayerState'] as InteractiveLayerState;

    const picks = this.getPicks(screenCoords);

    this.onPointerMove({
      screenCoords,
      get mapCoords(): GeoPoint {
        throw new Error(
          `Pointermove event has disabled mapCoords for performance.`,
        );
      },
      picks,
      pointerDownPicks,
      pointerDownScreenCoords,
      pointerDownMapCoords,
      sourceEvent: srcEvent,
      cancelPan: event.stopImmediatePropagation,
    });
  }

  private getPicks(screenCoords: [number, number]): Pick[] {
    return (this.context.deck?.pickMultipleObjects({
      x: screenCoords[0],
      y: screenCoords[1],
      layerIds: [this.props.id],
      radius: 10,
      depth: 5,
      unproject3D: true,
    }) ?? []) as unknown as Pick[];
  }

  private getScreenCoords(pointerEvent: any): GeoPoint {
    return [
      pointerEvent.clientX -
        (this.context.gl.canvas as HTMLCanvasElement).getBoundingClientRect()
          .left,
      pointerEvent.clientY -
        (this.context.gl.canvas as HTMLCanvasElement).getBoundingClientRect()
          .top,
    ];
  }

  protected getMapCoords(screenCoords: GeoPoint): GeoPoint {
    const [lng, lat] = this.context.viewport.unproject([
      screenCoords[0],
      screenCoords[1],
    ]);
    return [lng, lat];
  }
}
