import { Injectable } from '@angular/core';
import { OperatorStatus } from './robot-operator-overview-utils';

const ENABLED_OPERATOR_STATUS_LOCAL_STORAGE_PREFIX =
  'enabled-operator-statuses';

@Injectable()
export class RobotOperatorOverviewDisplayStateService {
  constructor() {}

  getDisplayOptions(): Set<OperatorStatus> {
    const enabledOperatorStatusesString = localStorage.getItem(
      ENABLED_OPERATOR_STATUS_LOCAL_STORAGE_PREFIX,
    );
    if (enabledOperatorStatusesString === null) {
      return new Set<OperatorStatus>([
        'DisplayFocusedViewOperators',
        'DisplaySupervisionViewOperators',
        'DisplayMaintenanceViewOperators',
        'DisplayClockedInOperators',
      ]);
    }
    return new Set<OperatorStatus>(JSON.parse(enabledOperatorStatusesString));
  }

  setEnabledOperatorStatuses(enabledOperatorStatuses: Set<OperatorStatus>) {
    localStorage.setItem(
      ENABLED_OPERATOR_STATUS_LOCAL_STORAGE_PREFIX,
      JSON.stringify(Array.from(enabledOperatorStatuses)),
    );
  }
}
