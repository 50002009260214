<div style="padding: 10px">
  <mat-form-field appearance="fill">
    <mat-label>Select a Type</mat-label>
    <mat-select [(ngModel)]="selectedDataType">
      @for (option of dataTypeOptions; track option) {
        <mat-option [value]="option">{{ option }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <br />
  <mat-form-field appearance="fill">
    <mat-label>Key</mat-label>
    <input matInput placeholder="Key" [(ngModel)]="metadataKey" />
  </mat-form-field>
  <br />

  <mat-form-field appearance="fill">
    <mat-label>Value</mat-label>
    <input matInput placeholder="Value" [(ngModel)]="metadataValue" />
  </mat-form-field>
  <br />
  <button mat-raised-button color="primary" (click)="onAddMetadata()">
    Submit
  </button>
  <app-mass-action-progress-bar [progressReport]="progressReport" />
</div>
