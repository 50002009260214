import lineIntersect from '@turf/line-intersect';
import { lineString as turfLineString } from '@turf/helpers';
import { GeoPoint } from '@cartken/map-types';

export function lineStringsOverlap(
  lineStringCoords1: GeoPoint[],
  lineStringCoords2: GeoPoint[],
) {
  const currentLine = turfLineString(lineStringCoords1);
  const otherLines = turfLineString(lineStringCoords2);
  const intersectingPoints = lineIntersect(currentLine, otherLines);
  return intersectingPoints.features.length > 0;
}

// Checks if the last segment of the linestring self-overlaps.
export function lineStringSelfOverlaps(lineStringCoords: GeoPoint[]) {
  const len = lineStringCoords.length;
  if (len < 4) {
    return false;
  }
  const lastTwoPoints = lineStringCoords.slice(-2);
  const rest = lineStringCoords.slice(0, -2);
  return lineStringsOverlap(lastTwoPoints, rest);
}
