import { ViewName } from './user-session/user-session.utils';

export type UserSession =
  | {
      startAt: undefined;
      lastUpdateAt: string;
      latencyMillis: undefined;
      viewName: undefined;
      assignedRobots?: string[];
      enabledRobotSlotCount: undefined;
      traveledDistance: undefined;
      commitHash?: string;
      selectedAccessGroups: string[];
    }
  | {
      startAt: string;
      lastUpdateAt: string;
      latencyMillis: number;
      viewName: ViewName;
      assignedRobots?: string[];
      enabledRobotSlotCount: number;
      traveledDistance: number;
      commitHash?: string;
      selectedAccessGroups?: string[];
    };

export interface User {
  _id: string; //FIXME
  id: string;
  uid: string;
  instanceUuid: string;
  username: string;
  roles: string[];
  accessGroups: string[];
  displayName: string;
  pictureUrl?: string;
  email?: string;
  landingPage?: string;
  passwordHash?: string;
  session?: UserSession;
  refreshDurationMinutes?: number;
  apiKey?: string;
  clockedInAt?: string;
}

export enum Role {
  ADMIN = 'admin',
  OPERATIONS_USER = 'operations-user',
  OPERATIONS_MANAGER = 'operations-manager',
  OPERATIONS_VIEWER = 'operations-viewer',
  ROBOT_MAINTAINER = 'robot-maintainer',
  ROBOT_OPERATOR = 'robot-operator',
  MAP_EDITOR = 'map-editor',
  ROBOT = 'robot',
}
