import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ErrorService } from '../../../app/core/error-system/error.service';
import { Order } from '../../../app/core/order/order';
import { Robot } from '../../../app/robots/robot.types';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateOrderDialogComponent,
  CreateOrderDialogData,
  CreateOrderDialogResult,
} from '../create-order-dialog/create-order-dialog.component';
import { BackendService } from '../../core/backend.service';
import { Operation } from '../operation';
import { ZoomPanTriggerEvent } from './robot-order-map.component';
import { createOrderCards, OrderCard } from './mobile-order-card';
import { createRobotCard, RobotCard } from './mobile-robot-card';
import {
  CancelOrderDialogComponent,
  CancelOrderDialogData,
  CancelOrderDialogOutput,
} from './cancel-order-dialog.component';
import {
  CompartmentsDialogComponent,
  CompartmentsDialogData,
} from '../../../app/core/compartments-dialog/compartments-dialog.component';
import { AuthService } from '../../../app/core/auth.service';
import { visiblePageTimer } from '../../../utils/page-visibility';
import { hasCreateOrderAccess } from './operation-live-view-utils';

const POLLING_INTERVAL_MS = 5 * 1000;

enum RefreshScope {
  Orders = 'orders',
  Robots = 'robots',
  Operation = 'operation',
  Everything = 'everything',
}

function filterRefreshScope(targetRefreshScore: RefreshScope) {
  return filter(
    (refreshScope) =>
      refreshScope === RefreshScope.Everything ||
      refreshScope === targetRefreshScore,
  );
}

function isRobotActive(robot: Robot) {
  return robot.readyForOrders && (robot.scheduledStops?.length ?? 0) >= 0;
}

@Component({
  selector: 'app-mobile-operation-live-view',
  templateUrl: './mobile-operation-live-view.component.html',
  styleUrls: ['./mobile-operation-live-view.component.sass'],
})
export class MobileOperationLiveViewComponent implements OnInit, OnDestroy {
  orderCards?: OrderCard[];

  operation$!: Observable<Operation>;
  operationDisplayName$!: Observable<string>;
  canCreateOrders$!: Observable<boolean>;
  isReadyForOrders$!: Observable<boolean>;
  orderCards$!: Observable<OrderCard[]>;
  orders$!: Observable<Order[]>;
  robotCards$!: Observable<RobotCard[]>;
  robots$!: Observable<Robot[]>;

  highlightItemId?: string;

  private _destroy$ = new Subject<void>();
  private refresh$ = new BehaviorSubject<RefreshScope>(RefreshScope.Everything);

  constructor(
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private backendService: BackendService,
    private dialog: MatDialog,
    private auth: AuthService,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  async ngOnInit() {
    visiblePageTimer(0, POLLING_INTERVAL_MS)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.refresh$.next(RefreshScope.Everything);
      });

    this.canCreateOrders$ = this.auth.user$.pipe(
      takeUntil(this._destroy$),
      map(hasCreateOrderAccess),
    );
    const operationId = await this.parseOperationId();
    this.operation$ = this.getOperation(operationId);

    this.operationDisplayName$ = this.operation$.pipe(
      map((operation) => operation.displayName ?? operation.id),
    );

    this.orders$ = this.getOrders(operationId);
    this.robots$ = this.getRobots(operationId);

    this.isReadyForOrders$ = combineLatest([
      this.robots$,
      this.errorService.isInErrorState$,
    ]).pipe(
      map(
        ([robots, isInErrorState]) =>
          !isInErrorState && robots.some((robot) => robot.readyForOrders),
      ),
    );
    this.robotCards$ = combineLatest([this.orders$, this.robots$]).pipe(
      map(([orders, robots]) => {
        const ordersById = new Map(orders.map((order) => [order.id, order]));
        return robots.map((robot) => createRobotCard(robot, ordersById));
      }),
    );

    this.orderCards$ = combineLatest([this.orders$, this.robots$])
      .pipe(takeUntil(this._destroy$))
      .pipe(
        map(([orders, robots]) => {
          return createOrderCards(orders, robots);
        }),
      );
  }

  private parseOperationId() {
    return firstValueFrom(
      this.route.paramMap.pipe(
        switchMap((params) => {
          const operationId = params.get('operation-id');

          if (operationId === null) {
            this.errorService.reportError(
              `URL is not correct, operation-id is not found`,
            );
            return EMPTY;
          }

          return of(operationId);
        }),
      ),
    );
  }

  private getOperation(operationId: string): Observable<Operation> {
    return this.refresh$.pipe(
      filterRefreshScope(RefreshScope.Operation),
      switchMap(() =>
        this.backendService
          .get<Operation>(`/operations/${operationId}`)
          .pipe(
            this.errorService.handleStreamErrors(
              `Operation with name '${operationId}' could not be retrieved.`,
            ),
          ),
      ),
      shareReplay(1),
    );
  }

  private getOrders(operationId: string) {
    return this.refresh$.pipe(
      filterRefreshScope(RefreshScope.Orders),
      switchMap(() =>
        this.backendService.get(
          `/orders?operation_id=${operationId}&status=active&bulk=true`,
        ),
      ),
      this.errorService.handleStreamErrors('Can not get updated orders'),
      shareReplay(1),
    );
  }

  private getRobots(operationId: string) {
    return this.refresh$.pipe(
      filterRefreshScope(RefreshScope.Robots),
      switchMap(() =>
        this.backendService.get(`/robots?assigned_operation_id=${operationId}`),
      ),
      this.errorService.handleStreamErrors('Can not get updated robots'),
      map((robots: Robot[]) => robots.filter(isRobotActive)),
      shareReplay(1),
    );
  }

  compareById(_: number, item?: { id: string }) {
    return item?.id;
  }

  highlightItem(id: string) {
    if (id === this.highlightItemId) {
      this.highlightItemId = undefined;
      return;
    }
    this.highlightItemId = id;
  }

  onZoomPanChange(zoomPanEvent: ZoomPanTriggerEvent) {
    if (zoomPanEvent.triggerId === this.highlightItemId) {
      return;
    }

    this.highlightItemId = undefined;
  }

  async cancelOrder(order: Order) {
    const orderDeletionResult = await firstValueFrom(
      this.dialog
        .open<
          CancelOrderDialogComponent,
          CancelOrderDialogData,
          CancelOrderDialogOutput
        >(CancelOrderDialogComponent, {
          data: { orderId: order.id },
          minWidth: '95vw',
        })
        .afterClosed(),
    );
    if (orderDeletionResult?.isDeleted) {
      this.refresh$.next(RefreshScope.Orders);
    }
  }

  openCompartmentDialog(robot: Robot) {
    this.dialog.open<CompartmentsDialogComponent, CompartmentsDialogData>(
      CompartmentsDialogComponent,
      {
        data: { robotId: robot.id },
        minWidth: '95vw',
      },
    );
  }

  async openCreateOrderDialog() {
    const operation = await firstValueFrom(this.operation$);
    const createOrderDialogResult = await firstValueFrom(
      this.dialog
        .open<
          CreateOrderDialogComponent,
          CreateOrderDialogData,
          CreateOrderDialogResult
        >(CreateOrderDialogComponent, {
          data: { operationId: operation.id },
          minWidth: '95vw',
          maxHeight: '95vh',
          height: 'fit-content',
        })
        .afterClosed(),
    );
    if (createOrderDialogResult?.isCreated) {
      this.refresh$.next(RefreshScope.Orders);
    }
  }
}
