import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LineStringGeometry } from '@cartken/map-types';
import { isDefined } from '../../utils/typeGuards';
import { BackendService } from '../core/backend.service';
import { Geometry } from './mapping.component';
import { firstValueFrom } from 'rxjs';

function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean,
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

interface MetaData {
  robotName: string;
  hash: string;
  start: Date;
  end: Date;
  trajectory_length: number;
  polyline?: LineStringGeometry;
}

@Component({
  selector: 'map-recordings',
  templateUrl: './map-recordings.component.html',
  styleUrls: ['./map-recordings.component.sass'],
})
export class MapRecordingsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  metadataFiles: MetaData[] = [];
  displayedColumns: string[] = ['robot', 'start', 'end', 'length', 'buttons'];
  dataSource = new MatTableDataSource<MetaData>([]);

  @Output()
  onShowTrajectory = new EventEmitter<Geometry>();

  constructor(private backendService: BackendService) {}

  ngOnInit(): void {
    this.loadMetaDataFiles().catch((e) => console.warn(e));
  }

  async loadMetaDataFiles(): Promise<void> {
    const metadata_strings = await firstValueFrom(
      this.backendService.get<string[]>('/mapping/metadata'),
    );
    this.metadataFiles = metadata_strings.map((s) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const parsed = JSON.parse(s);
      return {
        robotName: parsed['robot_name'],
        hash: parsed['hash'],
        polyline: parsed['polyline'],
        start: new Date(parsed['recording_start'] * 1000),
        end: new Date(parsed['recording_end'] * 1000),
        trajectory_length: Math.trunc(Number(parsed['trajectory_length'])),
      } as MetaData;
    });

    this.dataSource.data = this.metadataFiles;
    this.dataSource.sort = this.sort;

    this.showAll();
  }

  async downloadContent(metadata: MetaData): Promise<void> {
    const signedUrl = await this.backendService
      .get<string>(`/mapping/content?hash=${metadata.hash}`)
      .toPromise();
    window.open(signedUrl, '_blank');
  }

  sortData(sort: Sort) {
    const data = this.metadataFiles.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'robot':
          return compare(a.robotName, b.robotName, isAsc);
        case 'start':
          return compare(a.start, b.start, isAsc);
        case 'end':
          return compare(a.end, b.end, isAsc);
        case 'length':
          return compare(a.trajectory_length, b.trajectory_length, isAsc);
        default:
          return 0;
      }
    });
  }

  showAll() {
    this.onShowTrajectory.emit({
      polylines: this.metadataFiles
        .map((file) => file.polyline)
        .filter(isDefined)
        .map((polyline) => ({ polyline, color: 'black' })),
    });
  }

  showTrajectory(file: MetaData) {
    if (file.polyline !== undefined) {
      this.onShowTrajectory.emit({
        polylines: [
          {
            polyline: file.polyline,
            color: 'black',
          },
        ],
      });
    }
  }
}
