import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SaveChangesetDialogData {
  title?: string;
  description?: string;
}

@Component({
  selector: 'save-changeset-dialog',
  templateUrl: 'save-changeset-dialog.component.html',
})
export class SaveChangesetDialogComponent {
  selectedOperationRegionId = '';

  constructor(
    public dialogRef: MatDialogRef<SaveChangesetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveChangesetDialogData,
  ) {}
}
