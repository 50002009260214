import { CompartmentState } from '../../core/robots-service/backend/robot.dto';
import { Order } from '../../core/order/order';
import { Robot } from '../../robots/robot.types';
import { getHandoverText } from './mobile-order-card';

const DEFAULT_ROBOT_IMG_URL = 'assets/robot/model-c-profile-picture.png';

type CompartmentIcons = 'widgets' | 'lock' | 'lock_open';

export type RobotCard = {
  id: string;
  robot: Robot;
  name: string;
  battery: number;
  isCharging?: boolean;
  robotGoal: string;
  icon: string;
  lockIcon: CompartmentIcons;
};

function handoverIndexToHandoverName(handoverIndex?: number) {
  switch (handoverIndex) {
    case 0:
      return 'pickup';
    case 1:
      return 'dropoff';
    case 2:
      return 'disposal';
    default:
      return 'storage';
  }
}

function detectRobotGoal(robot: Robot, ordersById: Map<string, Order>) {
  const nextStop = robot.scheduledStops?.[0];

  if (nextStop === undefined) {
    return 'Robot is idle';
  }

  const robotState = robot.arrivedAtStop ? 'Waiting at' : 'Driving to';

  const firstOrder = nextStop?.orders?.[0];

  if (firstOrder) {
    const goal = handoverIndexToHandoverName(
      firstOrder.handoverToCompleteIndex,
    );
    const order = ordersById.get(firstOrder.id);
    const handover =
      order?.handovers[
        firstOrder.handoverToCompleteIndex ?? order.currentHandoverIndex
      ];
    const handoverName = handover && getHandoverText(handover);
    return handoverName
      ? `${robotState} ${goal} at ${handoverName}`
      : `${robotState} ${goal}`;
  } else {
    const goal = robot.readyForOrders ? 'waiting queue' : 'storage';
    return `${robotState} ${goal}`;
  }
}

function selectCompartmentIcon(robot: Robot): CompartmentIcons {
  const compartments = robot.compartments ?? [];
  if (compartments.length > 1) {
    return 'widgets';
  }

  return compartments[0]?.state === CompartmentState.CLOSED_AND_LOCKED
    ? 'lock'
    : 'lock_open';
}

export function createRobotCard(
  robot: Robot,
  ordersById: Map<string, Order>,
): RobotCard {
  return {
    id: robot.id,
    robot,
    name: robot.shortName ?? robot.displayName,
    battery: robot.batteryPercentage ?? 0,
    robotGoal: detectRobotGoal(robot, ordersById),
    isCharging: robot.isCharging,
    icon: robot.pictureUrl ?? DEFAULT_ROBOT_IMG_URL,
    lockIcon: selectCompartmentIcon(robot),
  };
}
