import { MapElementManager } from '../map-elements/map-element-manager';
import { MapEditorMode } from './mode-manager';
import { ElementType, PointGeometryElement } from '@cartken/map-types';
import {
  ClickEvent,
  ModeProps,
  TentativeFeature,
} from '../visualization/types';
import { InteractiveMode } from '../visualization/interactive-mode';

function getElementType(mode?: MapEditorMode) {
  switch (mode) {
    case 'createAprilTag':
      return ElementType.APRIL_TAG;
    case 'createHandoverLocation':
      return ElementType.HANDOVER_LOCATION;
    case 'createInfrastructure':
      return ElementType.INFRASTRUCTURE;
    case 'createMutex':
      return ElementType.MUTEX;
    case 'createTrafficLight':
      return ElementType.TRAFFIC_LIGHT;
    default:
      return undefined;
  }
}

export class CreatePointFeatureMode extends InteractiveMode {
  private active = false;
  private elementType?: ReturnType<typeof getElementType>;

  constructor(private mapElementManager: MapElementManager) {
    super();
  }

  override setActive(active: boolean, mode?: MapEditorMode) {
    this.active = active;
    this.elementType = getElementType(mode);
    if (this.active) {
      this.mapElementManager.selectedMapElement = undefined;
    }
  }

  override createTentativeFeature(
    props: ModeProps,
  ): TentativeFeature | undefined {
    const lastCoords = props.lastHoverEvent?.mapCoords;
    if (!lastCoords) {
      return undefined;
    }
    return {
      type: 'Feature',
      properties: {
        guideType: 'tentative',
      },
      geometry: {
        type: 'Point',
        coordinates: lastCoords,
      },
    };
  }

  override onLeftClick({ mapCoords }: ClickEvent, props: ModeProps): void {
    if (!this.elementType) {
      return;
    }
    const pointFeature: PointGeometryElement = {
      id: this.mapElementManager.generateMapElementId(),
      version: this.mapElementManager.mapVersion() ?? 0,
      elementType: this.elementType,
      geometry: { type: 'Point', coordinates: mapCoords },
      // TODO: use properly initialized properties
      properties: {} as any,
    };
    this.mapElementManager.addChange([pointFeature]);
    this.mapElementManager.selectedMapElement = pointFeature;
  }
}
