import { Component } from '@angular/core';

@Component({
  selector: 'app-operation-live-view',
  templateUrl: './operation-live-view.component.html',
  styleUrls: ['./operation-live-view.component.sass'],
})
export class OperationLiveViewComponent {
  isMobile = window.matchMedia('(max-width: 600px)').matches;
}
