import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { VideoChannel } from '../../../core/robots-service/webrtc/types';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

export class KeyboardManualRobotControl {
  private readonly _brake$ = new Subject<void>();
  private readonly _videoChannel$ = new Subject<VideoChannel>();
  private readonly _toggleHazardLights$ = new Subject<void>();
  private readonly _zoomControl$ = new Subject<boolean>();
  private readonly _obstacleOverrideControl$ = new Subject<void>();
  private readonly _enableMicrophone$ = new Subject<boolean>();

  private readonly _resetState$ = new Subject<void>();

  private readonly unsubscribeEvents$ = new Subject<void>();

  private active = false;

  // Observables.
  readonly brake$ = this._brake$.asObservable();
  readonly videoChannel$ = this._videoChannel$.asObservable();
  readonly toggleHazardLights$ = this._toggleHazardLights$.asObservable();
  readonly zoomControl$ = this._zoomControl$
    .asObservable()
    .pipe(distinctUntilChanged());
  readonly obstacleOverrideControl$ =
    this._obstacleOverrideControl$.asObservable();
  readonly resetState$ = this._resetState$.asObservable();

  readonly enableMicrophone$ = this._enableMicrophone$.asObservable();

  constructor() {}

  setActive(active: boolean) {
    this._resetState$.next(undefined);
    this.unsubscribeEvents$.next(undefined);
    if (active) {
      this.subscribeEvents();
    }
    this.active = active;
  }

  private subscribeEvents() {
    fromEvent(window, 'keydown')
      .pipe(takeUntil(this.unsubscribeEvents$))
      .subscribe((event: Event) => {
        const isInputField =
          event.target instanceof HTMLInputElement &&
          (!event.target.type ||
            event.target.type === 'text' ||
            event.target.type === 'number');
        if (
          this.active &&
          !isInputField &&
          this.handleKeyDown(
            (event as KeyboardEvent).code,
            (event as KeyboardEvent).repeat,
          )
        ) {
          event.preventDefault();
        }
      });

    fromEvent(window, 'keyup')
      .pipe(takeUntil(this.unsubscribeEvents$))
      .subscribe((event: Event) => {
        if (this.active && this.handleKeyUp((event as KeyboardEvent).code)) {
          event.preventDefault();
        }
      });

    fromEvent(window, 'blur')
      .pipe(takeUntil(this.unsubscribeEvents$))
      .subscribe(() => {
        // Stop and reset state when the window looses focus (and does not receive key events
        // anymore).
        this._resetState$.next(undefined);
      });
  }

  private handleKeyDown(keyCode: string, isRepeated: boolean): boolean {
    switch (keyCode) {
      case 'KeyO':
        this._obstacleOverrideControl$.next(undefined);
        break;
      case 'Digit1':
        this._videoChannel$.next(VideoChannel.Default);
        break;
      case 'Digit2':
        this._videoChannel$.next(VideoChannel.Surround);
        break;
      case 'Digit3':
        this._videoChannel$.next(VideoChannel.Reverse);
        break;
      case 'Digit4':
        this._videoChannel$.next(VideoChannel.Front);
        break;
      case 'Digit5':
        this._videoChannel$.next(VideoChannel._LocalizationDebugColor);
        break;
      case 'Digit6':
        this._videoChannel$.next(
          VideoChannel._SemanticSegmentationEncodingFrontImage,
        );
        break;
      case 'Digit7':
        this._videoChannel$.next(
          VideoChannel._SemanticSegmentationEncodingLeftImage,
        );
        break;
      case 'Digit8':
        this._videoChannel$.next(
          VideoChannel._SemanticSegmentationEncodingRightImage,
        );
        break;
      case 'Digit9':
        this._videoChannel$.next(
          VideoChannel._SemanticSegmentationEncodingRearImage,
        );
        break;
      case 'KeyZ':
        this._zoomControl$.next(true);
        break;
      case 'KeyH':
      case 'KeyI':
        this._toggleHazardLights$.next();
        break;
      case 'KeyV':
        if (!isRepeated) {
          this._enableMicrophone$.next(true);
        }
        break;
      case 'Space':
        this._brake$.next();
        break;
      default:
        // Return false to signal that the key was not handled here.
        return false;
    }
    return true;
  }

  private handleKeyUp(keyCode: string): boolean {
    switch (keyCode) {
      case 'KeyZ':
        this._zoomControl$.next(false);
        break;
      case 'KeyV':
        this._enableMicrophone$.next(false);
        break;
      default:
        // Don't trigger control subject by default and return false to signal that the key was not
        // handled here.
        return false;
    }
    return true;
  }
}
