<div style="padding: 10px">
  @if (progressReport) {
    <div>
      Success count: {{ progressReport.successCount }}
      <mat-progress-bar
        mode="determinate"
        [value]="
          (progressReport.successCount / progressReport.totalCount) * 100
        "
      />
    </div>

    <div>
      Failure count: {{ progressReport.failureCount }}
      <mat-progress-bar
        color="warn"
        mode="determinate"
        [value]="
          (progressReport.failureCount / progressReport.totalCount) * 100
        "
      />
    </div>

    @if (progressReport.errorMessages.length > 0) {
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> Errors </mat-panel-title>
          <mat-panel-description>
            Click to see error which occurred while adding frames to collection
          </mat-panel-description>
        </mat-expansion-panel-header>
        @for (
          errorMessage of progressReport.errorMessages;
          track errorMessage
        ) {
          <div>
            {{ errorMessage }}
          </div>
        }
      </mat-expansion-panel>
    }
  }
</div>
