import {
  Component,
  Input,
  Output,
  Renderer2,
  RendererFactory2,
  EventEmitter,
} from '@angular/core';
import { orderBounds } from '../../core/order/order-utils';
import { Order } from '../../core/order/order';

import { toGoogleLatLng } from '../../../utils/geo-tools';
import { RobotsBackendService } from '../../core/robots-service/robots-backend.service';
import { Robot } from '../../../app/robots/robot.types';
import { Operation } from '../operation';
import { Nullable } from '../../../utils/nullable';
import { routeBounds } from '../../../app/core/robots-service/backend/utils';
import { GeoPoint } from '@cartken/map-types';

const MIN_MAP_ZOOM_LEVEL = 15;

type Marker = {
  marker: google.maps.Marker;
  isUpdated: boolean;
};

const ROBOT_ICON = {
  url: 'assets/robot.png', // url
  scaledSize: new google.maps.Size(40, 40), // scaled size
  origin: new google.maps.Point(0, 0), // origin
  anchor: new google.maps.Point(0, 0), // anchor
  labelOrigin: new google.maps.Point(50, 15),
};

export type ZoomPanTriggerEvent = {
  triggerId?: string;
};

const PICKUP_ICON = {
  url: 'assets/shopping_basket.svg',
  scaledSize: new google.maps.Size(40, 40),
  anchor: new google.maps.Point(20, 5),
};

const DROPOFF_ICON = {
  url: 'assets/flag.svg',
  scaledSize: new google.maps.Size(40, 40),
  anchor: new google.maps.Point(9, 36),
};

const DISPOSE_ICON = {
  url: 'assets/trash_bin.svg',
  scaledSize: new google.maps.Size(40, 40),
  anchor: new google.maps.Point(9, 36),
};

@Component({
  selector: 'app-robot-order-map',
  templateUrl: './robot-order-map.component.html',
  styleUrls: ['./robot-order-map.component.sass'],
})
export class RobotOrderMapComponent {
  readonly googleMapOptions = {
    center: { lat: 37.740667, lng: -122.201146 },
    zoom: 18,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    tilt: 0,
    clickableIcons: false,
    fullscreenControl: false,
    streetViewControl: false,
    disableDoubleClickZoom: true,
  };

  private ordersById = new Map<string, Order>();
  private robotsById = new Map<string, Robot>();
  private googleMap!: google.maps.Map;

  private renderer: Renderer2;

  private activeOperation: Operation | undefined;

  private routeMarker?: google.maps.Polyline;
  private robotMarkersByRobotId = new Map<string, Marker>();
  private pickupMarker?: google.maps.Marker;
  private dropoffMarker?: google.maps.Marker;
  private disposeMarker?: google.maps.Marker;

  @Output()
  zoomPanChanged = new EventEmitter<ZoomPanTriggerEvent>();

  @Input()
  set highlightItemId(highlightItemId: Nullable<string>) {
    if (!highlightItemId) {
      this.removeRouteMarkers();
      this.removeOrderMarkers();
      return;
    }

    const robot = this.robotsById.get(highlightItemId);

    if (robot) {
      this.removeOrderMarkers();
      this.addRobotRouteMarkers(robot);
      return;
    }

    const order = this.ordersById.get(highlightItemId);

    if (order) {
      this.addOrderMarkers(order);
      return;
    }
  }

  @Input()
  set focusItemId(focusId: Nullable<string>) {
    if (!focusId) {
      this.removeRouteMarkers();
      this.removeOrderMarkers();
      return;
    }

    const robot = this.robotsById.get(focusId);
    if (robot) {
      this.removeOrderMarkers();
      this.addRobotRouteMarkers(robot);
      this.zoomToRobot(robot);
      return;
    }

    const order = this.ordersById.get(focusId);

    if (order) {
      this.addOrderMarkers(order);
      this.zoomToOrder(order);
    }
  }

  @Input()
  set robots(robots: Nullable<Robot[]>) {
    if (!robots) {
      return;
    }

    this.robotsById = new Map(robots.map((robot) => [robot.id, robot]));

    for (const [_, marker] of this.robotMarkersByRobotId) {
      marker.isUpdated = false;
    }

    for (const robot of robots) {
      this.updateRobotMarker(robot);
    }

    for (const [robotId, marker] of this.robotMarkersByRobotId) {
      if (!marker.isUpdated) {
        marker.marker.setMap(null);
        this.robotMarkersByRobotId.delete(robotId);
      }
    }
  }

  @Input()
  set operation(operation: Nullable<Operation>) {
    if (!operation) {
      return;
    }

    if (this.activeOperation?.id === operation.id) {
      return;
    }

    this.activeOperation = operation;
    this.zoomToBoundingPolygon();
  }

  @Input()
  set orders(orders: Nullable<Order[]>) {
    if (!orders) {
      return;
    }

    this.ordersById = new Map(orders.map((order) => [order.id, order]));
  }

  constructor(
    private robotsBackendService: RobotsBackendService,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  onGoogleMap(googleMap: google.maps.Map) {
    this.googleMap = googleMap;

    this.zoomToBoundingPolygon();
    this.addZoomToBoundingPolygonControl(this.googleMap);

    this.routeMarker = new google.maps.Polyline({
      strokeColor: 'green',
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 4,
          },
        },
      ],
    });
    this.googleMap.addListener('click', () => {
      this.zoomPanChanged.emit({});
    });
    this.zoomToBoundingPolygon();
  }

  private addZoomToBoundingPolygonControl(map: google.maps.Map) {
    const zoomOutButton = this.renderer.createElement('div');
    zoomOutButton.style.backgroundColor = '#fff';
    zoomOutButton.style.borderRadius = '2px';
    zoomOutButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    zoomOutButton.style.cursor = 'pointer';
    zoomOutButton.style.marginRight = '10px';
    zoomOutButton.style.height = '40px';
    zoomOutButton.style.width = '40px';
    zoomOutButton.style.textAlign = 'center';
    zoomOutButton.title = 'Click to recenter the map';
    zoomOutButton.style.display = 'flex';
    zoomOutButton.style.alignItems = 'center';
    zoomOutButton.style.justifyContent = 'center';
    zoomOutButton.addEventListener('click', () => {
      this.zoomToBoundingPolygon();
      this.zoomPanChanged.emit({});
    });

    const zoomOutIcon = this.renderer.createElement('mat-icon');
    this.renderer.appendChild(
      zoomOutIcon,
      this.renderer.createText('zoom_out_map'),
    );
    this.renderer.addClass(zoomOutIcon, 'mat-icon');
    this.renderer.addClass(zoomOutIcon, 'material-icons');
    zoomOutButton.appendChild(zoomOutIcon);

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM]?.push(zoomOutButton);
  }

  removeOrderMarkers() {
    this.pickupMarker?.setMap(null);
    this.pickupMarker = undefined;

    this.dropoffMarker?.setMap(null);
    this.dropoffMarker = undefined;

    this.disposeMarker?.setMap(null);
    this.disposeMarker = undefined;
  }

  removeRouteMarkers() {
    this.routeMarker?.setMap(null);
    this.routeMarker = undefined;
  }

  async zoomToRobot(robot: Robot) {
    if (!this.googleMap) {
      return;
    }
    const route = await this.robotsBackendService.getRobotRoute(robot.id);
    const bounds = routeBounds(route);

    bounds.extend(
      new google.maps.LatLng(robot.location.latitude, robot.location.longitude),
    );

    this.zoomToBounds(bounds, MIN_MAP_ZOOM_LEVEL);
    this.zoomPanChanged.emit({ triggerId: robot?.id });
  }

  async addRobotRouteMarkers(robot: Robot) {
    if (!this.googleMap) {
      return;
    }
    this.removeRouteMarkers();
    const route = await this.robotsBackendService.getRobotRoute(robot.id);
    this.removeRouteMarkers();
    if (route) {
      this.routeMarker = new google.maps.Polyline({
        map: this.googleMap,
        path: route.geometry?.map((point) => {
          const latLon = {
            lat: point.latitude,
            lng: point.longitude,
          };
          // override unsafe index access warning, since the value should be a tuple according to GeoJSON spec
          return new google.maps.LatLng(latLon);
        }),
        strokeColor: 'green',
      });
    }
  }

  zoomToOrder(order: Order) {
    if (!this.googleMap) {
      return;
    }
    const bounds = orderBounds(order);
    this.zoomToBounds(bounds, MIN_MAP_ZOOM_LEVEL);
    this.zoomPanChanged.emit({ triggerId: order.id });
  }

  addOrderMarkers(order: Order) {
    if (!this.googleMap) {
      return;
    }

    this.removeOrderMarkers();

    const pickup = order.handovers[0];
    if (pickup !== undefined) {
      this.pickupMarker = new google.maps.Marker({
        map: this.googleMap,
        icon: PICKUP_ICON,
        position: new google.maps.LatLng(pickup.latitude, pickup.longitude),
      });
    }

    const dropoff = order.handovers[1];
    if (dropoff !== undefined) {
      this.dropoffMarker = new google.maps.Marker({
        map: this.googleMap,
        icon: DROPOFF_ICON,
        position: new google.maps.LatLng(dropoff.latitude, dropoff.longitude),
      });
    }

    const dispose = order.handovers[2];
    if (dispose !== undefined) {
      this.disposeMarker = new google.maps.Marker({
        map: this.googleMap,
        icon: DISPOSE_ICON,
        position: new google.maps.LatLng(dispose.latitude, dispose.longitude),
      });
    }

    this.removeRouteMarkers();
    if (order.robotRoute) {
      this.routeMarker = new google.maps.Polyline({
        map: this.googleMap,
        path: order.robotRoute.flatMap((coords) => {
          const latLng = toGoogleLatLng(coords);
          if (!latLng) {
            return [];
          }
          return [latLng];
        }),
        strokeColor: 'green',
      });
    }
  }

  private zoomToBoundingPolygon() {
    if (!this.googleMap) {
      return;
    }

    const operationRegion = this.activeOperation?.operationRegion;
    const bounds = new google.maps.LatLngBounds();
    operationRegion?.coordinates?.forEach((polygon) => {
      polygon.forEach((point: GeoPoint) => {
        const latLon = toGoogleLatLng(point);
        if (latLon === undefined) {
          console.error('Point has incorrect shape', point);
          return;
        }
        bounds.extend(latLon);
      });
    });

    this.zoomToBounds(bounds);
    this.zoomPanChanged.emit({ triggerId: this.activeOperation?.id });
  }

  private updateRobotMarker(robot: Robot) {
    if (!this.robotMarkersByRobotId.has(robot.id)) {
      const newMarker = new google.maps.Marker();
      this.robotMarkersByRobotId.set(robot.id, {
        marker: newMarker,
        isUpdated: false,
      });
      newMarker.setIcon(ROBOT_ICON);
    }

    const marker = this.robotMarkersByRobotId.get(robot.id)!;

    marker.marker.setPosition(
      new google.maps.LatLng(robot.location.latitude, robot.location.longitude),
    );
    marker.marker.setVisible(true);

    marker.marker.setMap(this.googleMap);
    marker.marker.setLabel({
      text: robot.shortName ?? robot.displayName,
      fontSize: '20px',
      fontWeight: 'bold',
    });

    marker.isUpdated = true;
  }

  private zoomToBounds(bounds: google.maps.LatLngBounds, minZoom?: number) {
    this.googleMap.fitBounds(bounds, 0);
    const boundsZoom = this.googleMap.getZoom() ?? 15;
    const zoom = minZoom && boundsZoom < minZoom ? minZoom : boundsZoom;
    this.googleMap.setZoom(zoom); // restrict zoom level
  }
}
