import { Viewport } from '@deck.gl/core';
import {
  Bounds,
  TileBoundingBox,
  ZRange,
} from '@deck.gl/geo-layers/dist/tileset-2d';
import { Ellipsoid } from '@math.gl/geospatial';

export type TileIndex = { x: number; y: number; z: number };

// gets the bounding box of a viewport
function getBoundingBox(
  viewport: Viewport,
  zRange: number[] | undefined,
  extent: Bounds,
): Bounds {
  let bounds;
  if (zRange && zRange.length === 2) {
    const [minZ, maxZ] = zRange;
    const bounds0 = viewport.getBounds({ z: minZ });
    const bounds1 = viewport.getBounds({ z: maxZ });
    bounds = [
      Math.min(bounds0[0], bounds1[0]),
      Math.min(bounds0[1], bounds1[1]),
      Math.max(bounds0[2], bounds1[2]),
      Math.max(bounds0[3], bounds1[3]),
    ];
  } else {
    bounds = viewport.getBounds();
  }
  return [
    Math.max(bounds[0], extent[0]),
    Math.max(bounds[1], extent[1]),
    Math.min(bounds[2], extent[2]),
    Math.min(bounds[3], extent[3]),
  ];
}

export function tileToBoundingBox(
  index: TileIndex,
  enuCoordinateOrigin: [number, number],
  tileSize: number,
): TileBoundingBox {
  const xFactor =
    tileSize / (111111 * Math.cos((Math.PI / 180) * enuCoordinateOrigin[1]));
  const yFactor = tileSize / 111111;

  return {
    west: enuCoordinateOrigin[0] + index.x * xFactor,
    south: enuCoordinateOrigin[1] + index.y * yFactor,
    east: enuCoordinateOrigin[0] + (index.x + 1) * xFactor,
    north: enuCoordinateOrigin[1] + (index.y + 1) * yFactor,
  };
}

export function getLatLngAltFromEnu(
  enuOrigin: number[], // [lng,lat,alt]
  enuOffsetMeters: number[], // [x,y,z]
): number[] {
  const ecefOrigin = Ellipsoid.WGS84.cartographicToCartesian(enuOrigin);
  const ecefTenu = Ellipsoid.WGS84.eastNorthUpToFixedFrame(ecefOrigin);
  const pointEcef = ecefTenu.transform(enuOffsetMeters);
  return Ellipsoid.WGS84.cartesianToCartographic(pointEcef as number[]);
}

// Returns all tile indices in the current viewport. If the current zoom level is smaller
// than minZoom, return an empty array.
export function getTileIndices({
  viewport,
  minZoom,
  zRange,
  extent,
  tileSize,
}: {
  viewport: Viewport;
  maxZoom?: number;
  minZoom?: number;
  zRange?: ZRange;
  extent?: Bounds | null;
  tileSize?: number;
  zoomOffset?: number;
}) {
  if (!extent || !tileSize) {
    return [];
  }
  if (
    typeof minZoom === 'number' &&
    Number.isFinite(minZoom) &&
    viewport.zoom < minZoom
  ) {
    return [];
  }

  const bounds = getBoundingBox(viewport, zRange, extent);
  const xFactor = (111111 * Math.cos((Math.PI / 180) * bounds[1])) / tileSize;
  const yFactor = 111111 / tileSize;

  const xMin = Math.floor((bounds[0] - extent[0]) * xFactor);
  const xMax = Math.ceil((bounds[2] - extent[0]) * xFactor);
  const yMin = Math.floor((bounds[1] - extent[1]) * yFactor);
  const yMax = Math.ceil((bounds[3] - extent[1]) * yFactor);

  const tileIndices: TileIndex[] = [];
  for (let x = xMin; x < xMax && tileIndices.length < 200; x++) {
    for (let y = yMin; y < yMax && tileIndices.length < 200; y++) {
      tileIndices.push({ x, y, z: 0 });
    }
  }
  return tileIndices;
}
