<mat-toolbar>
  <div class="button-group">
    <button mat-icon-button (click)="openHomePage()">
      <mat-icon>home</mat-icon>
    </button>

    <mat-menu #appMenu="matMenu">
      <ng-content></ng-content>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>menu</mat-icon>
    </button>

    <app-clocking></app-clocking>
  </div>
  <div class="button-group">
    <button mat-icon-button (click)="openShortcuts()">
      <mat-icon>help_outline</mat-icon>
    </button>

    <img src="{{ (auth.user$ | async)?.pictureUrl }}" (click)="openProfile()" />
  </div>
</mat-toolbar>
