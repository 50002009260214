import * as v from 'valibot';
import { IdInteger, Integer } from '../common';
import { LineStringGeometry, PointGeometry, PolygonGeometry } from '../geometry';

export enum ElementType {
  NODE = 'Node',
  ROBOT_EDGE = 'RobotEdge',
  ROBOT_QUEUE_EDGE = 'RobotQueueEdge',
  ROAD_EDGE = 'RoadEdge',
  CACHED_ROAD_EDGE = 'CachedRoadEdge',
  TRAFFIC_LIGHT = 'TrafficLight',
  APRIL_TAG = 'AprilTag',
  POLE = 'Pole',
  OPERATION_REGION = 'OperationRegion',
  HANDOVER_LOCATION = 'HandoverLocation',
  MUTEX = 'Mutex',
  SLIPPY_TILES = 'SlippyTiles',
  LOCALIZATION_MAP_TILES = 'LocalizationMapTiles',
  INFRASTRUCTURE_EDGE = 'InfrastructureEdge', // Uses RobotEdgeProperties
  INFRASTRUCTURE_WAITING_EDGE = 'InfrastructureWaitingEdge', // Uses RobotEdgeProperties
  INFRASTRUCTURE = 'Infrastructure',
  MOVABLE_PLATFORM_EDGE = 'MovablePlatformEdge',
}

const MapElementBase = v.object({
  id: IdInteger,
  version: Integer,
  deleted: v.nullish(v.boolean()),
});

export function mapElementType<
  T extends ElementType,
  P extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>,
  G extends
    | typeof PointGeometry
    | typeof LineStringGeometry
    | typeof PolygonGeometry,
>(elementType: T, properties: P, geometry: G) {
  return v.object({
    ...MapElementBase.entries,
    elementType: v.literal(elementType),
    properties,
    geometry,
  });
}
