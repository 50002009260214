import { Heap } from '../../../utils/heap';
import { computeDistanceBetween, interpolate } from 'spherical-geometry-js';
import { ElementType, GeoPoint, isEdge, MapElement } from '@cartken/map-types';
import { LineStringCoordinates } from '../visualization/geojson-types';
import { hasAtLeastTwoElements } from '../../../utils/typeGuards';

type PriorityItem = {
  id: number;
  distance: number;
};

export type ReachableEdge = {
  coordinates: LineStringCoordinates;
  distance: number;
};

function truncateEdgeCoordinates(
  coordinates: GeoPoint[],
  edgeLength: number,
  truncationDistance: number,
): GeoPoint[] {
  if (edgeLength < truncationDistance) {
    return coordinates;
  }
  const truncatedPath: GeoPoint[] = [coordinates[0]!];
  let remainingDistance = truncationDistance;

  for (let j = 1; j < coordinates.length; ++j) {
    const segmentLength = computeDistanceBetween(
      coordinates[j - 1],
      coordinates[j],
    );
    if (remainingDistance < segmentLength) {
      const fraction = remainingDistance / segmentLength;
      const latLng = interpolate(coordinates[j - 1], coordinates[j], fraction);
      truncatedPath.push([latLng.lng(), latLng.lat()]);
      break;
    }
    truncatedPath.push(coordinates[j]!);
    remainingDistance -= segmentLength;
  }
  if (truncatedPath.length < 2) {
    truncatedPath.push(truncatedPath[0]!);
  }
  return truncatedPath;
}

export function computeAllReachableEdges(
  startNodeId: number,
  mapElements: Map<number, MapElement>,
  robotNodeGraph: Map<number, Array<number>>,
  maxRobotDistance: number,
  autonomyOnly: boolean,
): ReachableEdge[] {
  const comparator = (a: PriorityItem, b: PriorityItem) => {
    return a.distance < b.distance ? 1 : -1;
  };
  const openList = new Heap<PriorityItem>(comparator);
  const enqueuedNodes = new Set<number>();
  openList.push({ id: startNodeId, distance: 0.0 });
  enqueuedNodes.add(startNodeId);

  const visitedNodes = new Set<number>();
  const reachableEdges: ReachableEdge[] = [];

  while (openList.length !== 0) {
    const currentNode = openList.pop()!;
    if (visitedNodes.has(currentNode.id)) {
      continue;
    }
    visitedNodes.add(currentNode.id);
    const connectedEdgeIds = robotNodeGraph.get(currentNode.id) ?? [];
    for (const connectedEdgeId of connectedEdgeIds) {
      const connectedEdge = mapElements.get(connectedEdgeId);
      if (!connectedEdge || !isEdge(connectedEdge)) {
        continue;
      }
      const properties = connectedEdge.properties as any;
      if (properties.crossing?.buttonPressRequired || properties.blockedAt) {
        continue;
      }

      if (
        autonomyOnly &&
        connectedEdge.elementType !== ElementType.ROBOT_QUEUE_EDGE &&
        (!properties.startWidth || !properties.endWidth)
      ) {
        continue;
      }
      const updatedDistance =
        currentNode.distance + connectedEdge.properties.length;

      const oneway =
        connectedEdge.elementType === ElementType.ROBOT_QUEUE_EDGE ||
        properties.oneway;
      const reversed = currentNode.id === connectedEdge.properties.endNodeId;
      const nextId = reversed
        ? connectedEdge.properties.startNodeId
        : connectedEdge.properties.endNodeId;
      if (oneway && reversed) {
        continue;
      }

      if (updatedDistance < maxRobotDistance) {
        openList.push({
          id: nextId,
          distance: updatedDistance,
        });
        reachableEdges.push({
          distance: currentNode.distance,
          coordinates: connectedEdge.geometry.coordinates,
        });
      } else {
        const remainingDistance = maxRobotDistance - currentNode.distance;
        const coordinates = truncateEdgeCoordinates(
          reversed
            ? connectedEdge.geometry.coordinates.slice().reverse()
            : connectedEdge.geometry.coordinates,
          connectedEdge.properties.length,
          remainingDistance,
        );
        if (!hasAtLeastTwoElements(coordinates)) {
          throw new Error('Unreachable code reached.');
        }
        reachableEdges.push({
          distance: updatedDistance,
          coordinates,
        });
      }
    }
  }
  return reachableEdges;
}
