import * as v from 'valibot';
import { PositiveInteger } from './common';

export const Versions = v.object({
  latestVersion: v.optional(PositiveInteger),
  deployedVersion: v.optional(PositiveInteger),
});
export type Versions = v.InferOutput<typeof Versions>;

export const DeployVersion = v.object({
  version: v.pipe(PositiveInteger, v.minValue(0)),
});
export type DeployVersion = v.InferOutput<typeof DeployVersion>;
