import {
  CompositeLayer,
  FilterContext,
  Layer,
  LayersList,
} from '@deck.gl/core';

export type ZoomBoundedLayerProps = {
  minZoom?: number;
  maxZoom?: number;
  renderLayers(): Layer | null | LayersList;
};

export class ZoomBoundedLayer extends CompositeLayer<ZoomBoundedLayerProps> {
  static override layerName = 'ZoomBoundedLayer';

  override renderLayers(): Layer | null | LayersList {
    return this.props.renderLayers();
  }

  override filterSubLayer({ viewport }: FilterContext): boolean {
    return (
      viewport.zoom >= (this.props.minZoom ?? Number.NEGATIVE_INFINITY) &&
      viewport.zoom <= (this.props.maxZoom ?? Number.POSITIVE_INFINITY)
    );
  }
}
