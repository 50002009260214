import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataItem } from '../../data-viewer/data-viewer.service';

export interface MassActionFrameDialogData {
  frames: DataItem[];
}

@Component({
  selector: 'app-mass-action-frame-dialog',
  templateUrl: './mass-action-frame-dialog.component.html',
  styleUrls: ['./mass-action-frame-dialog.component.sass'],
})
export class MassActionFrameDialog {
  constructor(
    public dialogRef: MatDialogRef<MassActionFrameDialogData, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: MassActionFrameDialogData,
  ) {}
}
