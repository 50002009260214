import { MapElement } from '@cartken/map-types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { getBoundingBox } from '../map-elements/bounding-box-helpers';
import { LatLngBounds } from 'spherical-geometry-js';
import {
  ModeProps,
  NonInteractiveFeature,
  NonInteractiveFeatureCollection,
} from '../visualization/types';
import { VisualizationManager } from '../visualization/visualization-manager';
import { getLineColor } from '../visualization/visualization-styles';
import { MapEditorMode } from './mode-manager';
import { zOffsetGeometry } from './utils';
import { InteractiveMode } from '../visualization/interactive-mode';

export class DiffMode extends InteractiveMode {
  private changedMapElements: NonInteractiveFeature[] = [];

  constructor(
    private readonly mapElementManager: MapElementManager,
    private readonly visualizationManager: VisualizationManager,
  ) {
    super();
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    return {
      type: 'FeatureCollection',
      features: this.changedMapElements,
    };
  }

  override setActive(active: boolean, mode?: MapEditorMode) {
    if (active) {
      this.visualizationManager.setMapElementOpacity(0.1);
      this.createChangeVisualization();
    } else {
      this.visualizationManager.setMapElementOpacity(1);
    }
  }

  private createChangeVisualization() {
    this.changedMapElements = [];
    const changedMapElements = this.mapElementManager.getChanges();
    if (!changedMapElements.length) {
      return;
    }
    let bounds: LatLngBounds | undefined;
    for (const m of changedMapElements) {
      const bb = getBoundingBox(m);
      bounds = bounds && bb ? bounds.union(bb) : bb;
      this.createMapElementVisualization(m);
    }
    if (bounds) {
      this.visualizationManager.fitBounds(bounds);
    }
  }

  private createMapElementVisualization(mapElement: MapElement) {
    // Hide deleted map elements that were introduced in the current changes.
    if (mapElement.deleted && mapElement.id < 0) {
      return;
    }
    this.changedMapElements.push({
      type: 'Feature',
      geometry: mapElement.geometry,
      properties: {
        lineColor: [0, 255, 255, 255],
        lineWidth: 21,
        fillColor: [0, 0, 0, 0],
      },
    });
    this.changedMapElements.push({
      type: 'Feature',
      geometry: zOffsetGeometry(mapElement.geometry, 0.001),
      properties: {
        lineColor: mapElement.deleted
          ? [255, 0, 0, 255]
          : getLineColor(mapElement, false),
        lineWidth: 7,
        fillColor: [0, 0, 0, 0],
      },
    });
  }
}
