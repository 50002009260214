import * as v from 'valibot';
import { IdInteger, IdString, PositiveInteger } from '../common';

export const MappingDataAcquisitionInfo = v.object({
  numDesiredAcquisitions: v.optional(PositiveInteger),
  numRunningAcquisitions: v.optional(PositiveInteger),
  acquiringRobotIds: v.optional(v.array(IdString), []),
});
export type MappingDataAcquisitionInfo = v.InferOutput<
  typeof MappingDataAcquisitionInfo
>;

export const MapElementTransientData = v.object({
  id: IdInteger,
  mappingDataAcquisitionInfo: v.optional(MappingDataAcquisitionInfo),
});
export type MapElementTransientData = v.InferOutput<
  typeof MapElementTransientData
>;

export const UpsertMapElementTransientData = v.object({
  upsertedIds: v.tupleWithRest([IdInteger], IdInteger),
  setNumDesiredAcquisitions: v.optional(PositiveInteger),
  removeRunningAcquisitions: v.optional(v.boolean()),
});
export type UpsertMapElementTransientData = v.InferOutput<
  typeof UpsertMapElementTransientData
>;
