import { ElementType, OperationRegion } from '@cartken/map-types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { DrawPolygonMode } from './draw-polygon-mode';
import { FeatureOf, Polygon } from '../visualization/geojson-types';

export class CreateOperationRegionMode extends DrawPolygonMode {
  constructor(private mapElementManager: MapElementManager) {
    super();
  }

  override setActive(active: boolean) {
    super.setActive(active);
    if (active) {
      this.mapElementManager.selectedMapElement = undefined;
    }
  }

  protected override addPolygon(polygon: FeatureOf<Polygon>) {
    const operationRegion: OperationRegion = {
      id: this.mapElementManager.generateMapElementId(),
      elementType: ElementType.OPERATION_REGION,
      version: this.mapElementManager.mapVersion() ?? 0,
      geometry: {
        type: 'Polygon',
        coordinates: polygon.geometry.coordinates,
      },
      properties: {
        operationId: '', // TODO: generate proper id here at some point
      },
    };

    this.mapElementManager.addChange([operationRegion]);
    this.mapElementManager.selectedMapElement = operationRegion;
  }
}
