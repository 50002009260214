import { Robot } from '../../robots/robot.types';
import { Handover, Order, OrderStatus } from '../../../app/core/order/order';
const DEFAULT_ROBOT_IMG_URL = 'assets/robot/model-c-profile-picture.png';

function round(value: number, precision: number) {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
}

export function getHandoverText(handover: Handover) {
  if (handover.displayName) {
    return handover.displayName;
  }

  if (handover.businessName) {
    return handover.businessName;
  }

  if (handover.address) {
    return handover.address;
  }

  const latitude = round(handover.latitude, 6);
  const longitude = round(handover.longitude, 6);

  return `(${latitude}, ${longitude})`;
}

function getOrderFromTo(order: Order): HandoverData[] {
  return order.handovers.map((handover, index): HandoverData => {
    return {
      text: getHandoverText(handover),
      isCurrent: order.currentHandoverIndex === index,
    };
  });
}

function orderCreationDate(order: Order): Date | null {
  const orderCreationDateString = order.statusLog?.[0]?.timestamp;

  if (!orderCreationDateString) {
    return null;
  }

  return new Date(orderCreationDateString);
}

function getRobotName(order: Order, robotsById: Map<string, Robot>): string {
  const robot = robotsById.get(order.assignedRobotId ?? '');
  return robot?.shortName ?? robot?.displayName ?? 'NOT ASSIGNED';
}

function getRobotIcon(order: Order, robotsById: Map<string, Robot>): string {
  return (
    robotsById.get(order.assignedRobotId ?? '')?.pictureUrl ??
    DEFAULT_ROBOT_IMG_URL
  );
}

function orderState(order: Order): string {
  switch (order.status) {
    case OrderStatus.ASSIGNED:
      return 'assigned';
    case OrderStatus.CREATED:
      return 'looking for robot';
    case OrderStatus.DRIVING_TO_HANDOVER:
      switch (order.currentHandoverIndex) {
        case 0:
          return 'driving to pick up';
        case 1:
          return 'driving to drop off';
        case 2:
          return 'driving to disposal';
        default:
          return '...';
      }

    case OrderStatus.WAITING_FOR_HANDOVER:
      switch (order.currentHandoverIndex) {
        case 0:
          return 'waiting for pick up';
        case 1:
          return 'waiting for drop off';
        case 2:
          return 'waiting for disposal';
        default:
          return '...';
      }

    case OrderStatus.CANCELED:
      return 'Canceled';
    case OrderStatus.FAILED:
      return 'Failed';
    case OrderStatus.FULFILLED:
      return 'Fulfilled';
    case OrderStatus.REJECTED:
      return 'Rejected';
  }
}

export type HandoverData = {
  isCurrent: boolean;
  text: string;
};

export type OrderCard = {
  id: string;
  handovers: HandoverData[];
  robotName: string;
  status: string;
  createdAt?: Date | null;
  order: Order;
  robotIcon?: string;
};

export function createOrderCards(
  orders: Order[],
  robots: Robot[],
): OrderCard[] {
  const robotsById = new Map(robots.map((robot: Robot) => [robot.id, robot]));
  return orders.map((order: Order) => {
    return {
      id: order.id,
      handovers: getOrderFromTo(order),
      robotName: getRobotName(order, robotsById),
      status: orderState(order),
      createdAt: orderCreationDate(order),
      order: order,
      robotIcon: getRobotIcon(order, robotsById),
    } as OrderCard;
  });
}
