<p style="margin-left: 5px; font-size: 20px">
  Operating on {{ data.frames.length }} frames
</p>
<mat-tab-group dynamicHeight>
  <mat-tab label="Add frames to collection">
    <app-add-frames-to-collection [frames]="this.data.frames" />
  </mat-tab>

  <mat-tab label="Add metadata">
    <app-add-frame-metadata [frames]="this.data.frames" />
  </mat-tab>
</mat-tab-group>
