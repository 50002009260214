import { GeoPoint } from '@cartken/map-types';
import {
  Feature,
  FeatureOf,
  Geometry,
  MultiLineString,
  MultiPolygon,
  Polygon,
  PolygonCoordinates,
} from '../visualization/geojson-types';
import rewind from '@turf/rewind';

function recursivelyRemovePosition(
  coordinates: any,
  positionIndexes: number[] | null | undefined,
  isPolygonal: boolean,
): any {
  if (!positionIndexes) {
    return coordinates;
  }
  if (positionIndexes.length === 0) {
    throw Error('Must specify the index of the position to remove');
  }
  if (positionIndexes.length === 1) {
    const updated = [
      ...coordinates.slice(0, positionIndexes[0]),
      ...coordinates.slice(positionIndexes[0] + 1),
    ];

    if (
      isPolygonal &&
      (positionIndexes[0] === 0 ||
        positionIndexes[0] === coordinates.length - 1)
    ) {
      // for polygons, the first point is repeated at the end of the array
      // so, if the first/last coordinate is to be removed, coordinates[1] will be the new first/last coordinate
      if (positionIndexes[0] === 0) {
        // change the last to be the same as the first
        updated[updated.length - 1] = updated[0];
      } else if (positionIndexes[0] === coordinates.length - 1) {
        // change the first to be the same as the last
        updated[0] = updated[updated.length - 1];
      }
    }
    return updated;
  }

  // recursively update inner array
  return [
    ...coordinates.slice(0, positionIndexes[0]),
    recursivelyRemovePosition(
      coordinates[positionIndexes[0]],
      positionIndexes.slice(1, positionIndexes.length),
      isPolygonal,
    ),
    ...coordinates.slice(positionIndexes[0] + 1),
  ];
}

function pruneGeometryIfNecessary(geometry: Geometry) {
  switch (geometry.type) {
    case 'Polygon':
      prunePolygonIfNecessary(geometry);
      break;
    case 'MultiLineString':
      pruneMultiLineStringIfNecessary(geometry);
      break;
    case 'MultiPolygon':
      pruneMultiPolygonIfNecessary(geometry);
      break;
    default:
      // Not downgradable
      break;
  }
}

function prunePolygonIfNecessary(geometry: Polygon) {
  const polygon = geometry.coordinates;

  // If any hole is no longer a polygon, remove the hole entirely
  for (let holeIndex = 1; holeIndex < polygon.length; holeIndex++) {
    if (removeHoleIfNecessary(polygon, holeIndex)) {
      // It was removed, so keep the index the same
      holeIndex--;
    }
  }
}

function pruneMultiLineStringIfNecessary(geometry: MultiLineString) {
  for (
    let lineStringIndex = 0;
    lineStringIndex < geometry.coordinates.length;
    lineStringIndex++
  ) {
    const lineString = geometry.coordinates[lineStringIndex];
    if (lineString.length === 1) {
      // Only a single position left on this LineString, so remove it (can't have Point in MultiLineString)
      geometry.coordinates.splice(lineStringIndex, 1);
      // Keep the index the same
      lineStringIndex--;
    }
  }
}

function pruneMultiPolygonIfNecessary(geometry: MultiPolygon) {
  for (
    let polygonIndex = 0;
    polygonIndex < geometry.coordinates.length;
    polygonIndex++
  ) {
    const polygon = geometry.coordinates[polygonIndex];
    const outerRing = polygon[0];

    // If the outer ring is no longer a polygon, remove the whole polygon
    if (outerRing.length <= 3) {
      geometry.coordinates.splice(polygonIndex, 1);
      // It was removed, so keep the index the same
      polygonIndex--;
    }

    for (let holeIndex = 1; holeIndex < polygon.length; holeIndex++) {
      if (removeHoleIfNecessary(polygon, holeIndex)) {
        // It was removed, so keep the index the same
        holeIndex--;
      }
    }
  }
}

function removeHoleIfNecessary(polygon: PolygonCoordinates, holeIndex: number) {
  const hole = polygon[holeIndex];
  if (hole.length <= 3) {
    polygon.splice(holeIndex, 1);
    return true;
  }
  return false;
}

// Removes a position deeply nested in a GeoJSON geometry coordinates array.
// Works with MultiPoint, LineString, MultiLineString, Polygon, and MultiPolygon.
export function removePosition(
  geometry: Geometry,
  positionIndexes: number[] | undefined,
): Geometry {
  const clonedGeometry = structuredClone(geometry);

  if (geometry.type === 'Point') {
    throw Error(
      `Can't remove a position from a Point or there'd be nothing left`,
    );
  }
  if (
    geometry.type === 'MultiPoint' && // only 1 point left
    geometry.coordinates.length < 2
  ) {
    throw Error(
      `Can't remove the last point of a MultiPoint or there'd be nothing left`,
    );
  }
  if (
    geometry.type === 'LineString' && // only 2 positions
    geometry.coordinates.length < 3
  ) {
    throw Error(
      `Can't remove position. LineString must have at least two positions`,
    );
  }
  if (
    geometry.type === 'Polygon' && // outer ring is a triangle
    geometry.coordinates[0].length < 5 &&
    Array.isArray(positionIndexes) && // trying to remove from outer ring
    positionIndexes[0] === 0
  ) {
    throw Error(
      `Can't remove position. Polygon's outer ring must have at least four positions`,
    );
  }
  if (
    geometry.type === 'MultiLineString' && // only 1 LineString left
    geometry.coordinates.length === 1 && // only 2 positions
    geometry.coordinates[0].length < 3
  ) {
    throw Error(
      `Can't remove position. MultiLineString must have at least two positions`,
    );
  }
  if (
    geometry.type === 'MultiPolygon' && // only 1 polygon left
    geometry.coordinates.length === 1 && // outer ring is a triangle
    geometry.coordinates[0][0].length < 5 &&
    Array.isArray(positionIndexes) && // trying to remove from first polygon
    positionIndexes[0] === 0 && // trying to remove from outer ring
    positionIndexes[1] === 0
  ) {
    throw Error(
      `Can't remove position. MultiPolygon's outer ring must have at least four positions`,
    );
  }

  const isPolygonal =
    geometry.type === 'Polygon' || geometry.type === 'MultiPolygon';
  clonedGeometry.coordinates = recursivelyRemovePosition(
    clonedGeometry.coordinates,
    positionIndexes,
    isPolygonal,
  );

  // Handle cases where incomplete geometries need pruned (e.g. holes that were triangles)
  pruneGeometryIfNecessary(clonedGeometry);

  return clonedGeometry;
}

function recursivelyAddPosition(
  coordinates: any,
  positionIndexes: number[] | null | undefined,
  positionToAdd: GeoPoint,
  isPolygonal: boolean,
): any {
  if (!positionIndexes) {
    return coordinates;
  }
  const currentIndex = positionIndexes[0];
  const remainingIndices = positionIndexes.slice(1);
  if (currentIndex === undefined) {
    throw Error('Must specify the index of the position to remove');
  }
  if (remainingIndices.length === 0) {
    const updated = [
      ...coordinates.slice(0, currentIndex),
      positionToAdd,
      ...coordinates.slice(currentIndex),
    ];
    return updated;
  }

  // recursively update inner array
  return [
    ...coordinates.slice(0, currentIndex),
    recursivelyAddPosition(
      coordinates[currentIndex],
      remainingIndices,
      positionToAdd,
      isPolygonal,
    ),
    ...coordinates.slice(currentIndex + 1),
  ];
}

// Adds a position deeply nested in a GeoJSON geometry coordinates array.
// Works with MultiPoint, LineString, MultiLineString, Polygon, and MultiPolygon.
export function addPosition(
  geometry: Geometry,
  positionIndexes: number[] | undefined,
  positionToAdd: GeoPoint,
): Geometry {
  const clonedGeometry = structuredClone(geometry);
  if (clonedGeometry.type === 'Point') {
    throw new Error('Unable to add a position to a Point feature');
  }

  const isPolygonal =
    clonedGeometry.type === 'Polygon' || clonedGeometry.type === 'MultiPolygon';
  clonedGeometry.coordinates = recursivelyAddPosition(
    clonedGeometry.coordinates,
    positionIndexes,
    positionToAdd,
    isPolygonal,
  );
  return clonedGeometry;
}

function recursivelyReplacePosition(
  coordinates: any,
  positionIndexes: number[] | null | undefined,
  updatedPosition: GeoPoint,
  isPolygonal: boolean,
): any {
  if (!positionIndexes?.length) {
    return updatedPosition;
  }
  if (positionIndexes.length === 1) {
    const updated = [
      ...coordinates.slice(0, positionIndexes[0]),
      updatedPosition,
      ...coordinates.slice(positionIndexes[0] + 1),
    ];

    if (
      isPolygonal &&
      (positionIndexes[0] === 0 ||
        positionIndexes[0] === coordinates.length - 1)
    ) {
      // for polygons, the first point is repeated at the end of the array
      // so, update it on both ends of the array
      updated[0] = updatedPosition;
      updated[coordinates.length - 1] = updatedPosition;
    }
    return updated;
  }

  // recursively update inner array
  return [
    ...coordinates.slice(0, positionIndexes[0]),
    recursivelyReplacePosition(
      coordinates[positionIndexes[0]],
      positionIndexes.slice(1, positionIndexes.length),
      updatedPosition,
      isPolygonal,
    ),
    ...coordinates.slice(positionIndexes[0] + 1),
  ];
}

// Replaces the position deeply nested withing the given feature's geometry.
// Works with Point, MultiPoint, LineString, MultiLineString, Polygon, and MultiPolygon.
export function replacePosition(
  geometry: Geometry,
  positionIndexes: number[] | undefined,
  updatedPosition: GeoPoint,
): Geometry {
  const clonedGeometry = structuredClone(geometry);
  const isPolygonal =
    clonedGeometry.type === 'Polygon' || clonedGeometry.type === 'MultiPolygon';
  clonedGeometry.coordinates = recursivelyReplacePosition(
    clonedGeometry.coordinates,
    positionIndexes,
    updatedPosition,
    isPolygonal,
  );
  return clonedGeometry;
}

// Rewind (Multi)Polygon outer ring counterclockwise and inner rings
// clockwise (Uses Shoelace Formula).
export function rewindPolygon<T extends Polygon | MultiPolygon>(
  feature: FeatureOf<T>,
): FeatureOf<T> {
  const { geometry } = feature;

  const isPolygonal =
    geometry.type === 'Polygon' || geometry.type === 'MultiPolygon';
  if (isPolygonal) {
    // @ts-expect-error
    return rewind(feature);
  }

  return feature;
}
