import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { BackendService } from './backend.service';
import { User } from './user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private backendService: BackendService,
    private auth: AuthService,
  ) {}

  getUsers(): Promise<User[]> {
    return lastValueFrom(this.backendService.get<User[]>('/users'));
  }

  getUser(id: string): Promise<User | undefined> {
    return lastValueFrom(
      this.backendService.get<User | undefined>(`/users/${id}`),
    );
  }

  async getSelf(): Promise<User | undefined> {
    const authUser = await firstValueFrom(this.auth.user$);
    if (!authUser) {
      return undefined;
    }
    return this.getUser(authUser._id);
  }
}
