import {
  getPickedExistingEditHandle,
  getHandlesForPick,
  getUnderlyingFeaturePick,
} from '../visualization/utils';
import { Feature } from '../visualization/geojson-types';
import {
  ModeProps,
  ClickEvent,
  GuideFeatureCollection,
} from '../visualization/types';
import { MapElementManager } from '../map-elements/map-element-manager';
import { InteractiveMode } from '../visualization/interactive-mode';
import { removePosition } from './geometry-manipulation-utils';

export class DeleteMode extends InteractiveMode {
  constructor(private readonly mapElementManager: MapElementManager) {
    super();
  }

  override getGuides(props: ModeProps): GuideFeatureCollection {
    const hoveredPick = getUnderlyingFeaturePick(
      props.lastHoverEvent?.picks[0],
      props,
    );
    return {
      type: 'FeatureCollection',
      features: getHandlesForPick(hoveredPick),
    };
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const pickedExistingHandle = getPickedExistingEditHandle([event.picks[0]]);
    let changedFeature: Feature | undefined;
    if (pickedExistingHandle) {
      const { featureIndex, positionIndexes } = pickedExistingHandle.properties;
      changedFeature = structuredClone(props.data.features[featureIndex]);
      if (positionIndexes) {
        changedFeature.geometry = removePosition(
          changedFeature.geometry,
          positionIndexes,
        );
      }
    } else if (event.picks.length) {
      const featureIndex = event.picks[0].index;
      changedFeature = structuredClone(props.data.features[featureIndex]);
      changedFeature.deleted = true;
    }

    if (!changedFeature) {
      return;
    }
    this.mapElementManager
      // @ts-expect-error TODO
      .generateChange(changedFeature)
      .then((change) => {
        this.mapElementManager.addChange(change);
      })
      .catch((e) => console.error(e));
  }
}
