import { BehaviorSubject } from 'rxjs';
import { MapElementManager } from '../map-elements/map-element-manager';
import { CreateEdgeMode } from './create-edge-mode';
import { CreatePointFeatureMode } from './create-point-feature-mode';
import { DeleteMode } from './delete-mode';
import { SelectAndEditMode } from './select-and-edit-mode';
import { CreateOperationRegionMode } from './create-operation-region-mode';
import { DiffMode } from './diff-mode';
import { RebaseMode } from './rebase-mode';
import { VisualizationManager } from '../visualization/visualization-manager';
import { InteractiveMode } from '../visualization/interactive-mode';
import { ReachableEdgesMode } from './reachable-edges-mode';
import { RoutingMode } from './routing-mode';
import { RoutesService } from '../../core/route-service';

export type MapEditorMode =
  | 'select'
  | 'createEdge'
  | 'createTrafficLight'
  | 'createAprilTag'
  | 'createHandoverLocation'
  | 'createMutex'
  | 'createInfrastructure'
  | 'createOperationRegion'
  | 'delete'
  | 'diff'
  | 'rebase'
  | 'reachability'
  | 'routing';

export class ModeManager {
  readonly modes;
  private _mapEditorMode$ = new BehaviorSubject<MapEditorMode>('select');
  private _modeChangesEnabled$ = new BehaviorSubject<boolean>(true);

  readonly mapEditorMode$ = this._mapEditorMode$.asObservable();
  readonly modeChangesEnabled$ = this._modeChangesEnabled$.asObservable();

  constructor(
    private readonly visualizationManager: VisualizationManager,
    mapElementManager: MapElementManager,
    routesService: RoutesService,
  ) {
    const pointFeatureMode = new CreatePointFeatureMode(mapElementManager);
    this.modes = {
      select: new SelectAndEditMode(mapElementManager),
      createEdge: new CreateEdgeMode(mapElementManager),
      createTrafficLight: pointFeatureMode,
      createAprilTag: pointFeatureMode,
      createHandoverLocation: pointFeatureMode,
      createMutex: pointFeatureMode,
      createInfrastructure: pointFeatureMode,
      createOperationRegion: new CreateOperationRegionMode(mapElementManager),
      diff: new DiffMode(mapElementManager, visualizationManager),
      delete: new DeleteMode(mapElementManager),
      rebase: new RebaseMode(visualizationManager),
      reachability: new ReachableEdgesMode(
        mapElementManager,
        visualizationManager,
      ),
      routing: new RoutingMode(visualizationManager, routesService),
    } satisfies Record<MapEditorMode, InteractiveMode>;
    this.visualizationManager.setMode(this.modes['select']);
  }

  setMapEditorMode(newEditMode: MapEditorMode) {
    if (
      !this._modeChangesEnabled$.value ||
      newEditMode === this._mapEditorMode$.value
    ) {
      return;
    }
    this.modes[this._mapEditorMode$.value].setActive(false);
    this.modes[newEditMode].setActive(true, newEditMode);

    this.visualizationManager.setMode(this.modes[newEditMode]);
    this._mapEditorMode$.next(newEditMode);
  }

  enableMapEditorModeChanges(enabled: boolean) {
    this._modeChangesEnabled$.next(enabled);
  }
}
