<div
  class="data-viewer-page-inner-container"
  [class.disable-gird]="dataViewerService.selectedItemId$ | async"
>
  <div class="data-viewer-column">
    <div class="data-viewer-menu">
      <mat-form-field>
        <mat-select
          [value]="dataViewerService.selectedDataViewerTab$ | async"
          (selectionChange)="
            dataViewerService.selectDataViewerTab($event.value)
          "
        >
          <mat-option class="tab-option" value="frames"> Frames </mat-option>
          <mat-option class="tab-option" value="snippets">
            Snippets
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-filter-selector class="data-viewer-filter"></app-filter-selector>
    </div>
    <div class="action-area">
      <div class="pick-options">
        <app-pick-label
          *ngIf="
            (dataViewerService.selectedDataViewerTab$ | async) === 'frames'
          "
          label="pick all"
          [isPicked]="true"
          (clicked)="dataViewerService.pickAllItems()"
        ></app-pick-label>
        <app-pick-label
          *ngIf="
            (dataViewerService.selectedDataViewerTab$ | async) === 'frames'
          "
          label="unpick all"
          [isPicked]="false"
          (clicked)="dataViewerService.unpickAllItems()"
        ></app-pick-label>
        <button
          *ngIf="
            (dataViewerService.selectedDataViewerTab$ | async) === 'frames'
          "
          mat-flat-button
          color="accent"
          (click)="openMassActionFramesDialog()"
          [disabled]="!(dataViewerService.isAnyItemsPicked$ | async)"
        >
          Action
        </button>
      </div>

      <app-sort-control
        [sortOptions]="sortingOptions"
        (onSortOptionUpdate)="updateSortingOrder($event)"
      ></app-sort-control>
    </div>

    <mat-divider></mat-divider>
    <app-data-viewer-grid class="grid-container"></app-data-viewer-grid>
  </div>
  <app-data-viewer-selection
    *ngIf="dataViewerService.selectedItemId$ | async"
    class="data-viewer-column"
  >
  </app-data-viewer-selection>
</div>
