import { toGoogleLatLng } from '../../../utils/geo-tools';

import { PrettyTimePipe } from '../pipes/pretty-time.pipe';
import { Handover, Order } from './order';

const prettyTime = new PrettyTimePipe();

export function formatHandoverString(handover?: Handover): string {
  const handoverTime = prettyTime.transform(
    handover?.estimatedArrivalTime?.toString()!,
  );
  return handover ? `${handoverTime} @ ${handover?.displayName}` : '';
}

export function formatIds(order: Order): string {
  const ids = [order.id];

  if (order.externalId !== undefined) {
    ids.push(order.externalId);
  }

  return ids.join(' / ');
}

export function formatCreated(order: Order): Date {
  return new Date(order.statusLog[0]?.timestamp?.toString()!);
}

export function orderBounds(order: Order): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();

  for (const point of order.robotRoute ?? []) {
    const latLon = toGoogleLatLng(point);
    if (!latLon) {
      continue;
    }
    bounds.extend(latLon);
  }

  return bounds;
}
