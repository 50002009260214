import { GeoPoint, MapElement } from '@cartken/map-types';
import { MapElementManager } from '../map-elements/map-element-manager';
import {
  Feature,
  Geometry,
  PointCoordinates,
} from '../visualization/geojson-types';
import { ModeProps } from '../visualization/types';

function zOffsetPoint(
  [lng, lat, alt]: GeoPoint,
  zOffset: number,
): PointCoordinates {
  return [lng, lat, (alt ?? 0) + zOffset];
}

function zOffsetLineString<T extends GeoPoint[]>(
  coords: T,
  zOffset: number,
): T {
  return coords.map((c) => zOffsetPoint(c, zOffset)) as T;
}

function zOffsetLineStringArray<T extends GeoPoint[][]>(
  coords: T,
  zOffset: number,
): T {
  return coords.map((c) => zOffsetLineString(c, zOffset)) as T;
}

export function zOffsetGeometry(geometry: Geometry, zOffset: number): Geometry {
  switch (geometry.type) {
    case 'Point':
      return {
        type: 'Point',
        coordinates: zOffsetPoint(geometry.coordinates, zOffset),
      };

    case 'LineString':
      return {
        type: geometry.type,
        coordinates: zOffsetLineString(geometry.coordinates, zOffset),
      };
    case 'MultiPoint':
      return {
        type: geometry.type,
        coordinates: zOffsetLineString(geometry.coordinates, zOffset),
      };
    case 'MultiLineString':
      return {
        type: geometry.type,
        coordinates: zOffsetLineStringArray(geometry.coordinates, zOffset),
      };
    case 'Polygon':
      return {
        type: geometry.type,
        coordinates: zOffsetLineStringArray(geometry.coordinates, zOffset),
      };
    default:
      return geometry;
  }
}

export function callOnEdit(
  changedFeature: Feature | undefined,
  mapElementManager: MapElementManager,
  editType: string,
  position: any,
  props: ModeProps,
) {
  if (!changedFeature) {
    return;
  }

  mapElementManager
    .generateChange(changedFeature as MapElement)
    .then((change) => {
      const updatedData = {
        ...props.data,
        features: [...props.data.features],
      };
      const featureIndexes = [];
      for (let i = 0; i < updatedData.features.length; ++i) {
        const updatedFeature = change.find(
          (f) => f.id === updatedData.features[i].id,
        );
        if (updatedFeature) {
          updatedData.features[i] = updatedFeature;
          featureIndexes.push(i);
        }
      }
      props.onEdit({
        updatedData,
        editType,
        editContext: {
          featureIndexes,
          position,
        },
      });
    })
    .catch((e) => console.error(e));
}
