import { GeoPoint, MapElement } from '@cartken/map-types';
import { LatLngBounds } from 'spherical-geometry-js';

export function boundsFromCoordinates(
  coordinates?: GeoPoint[],
): LatLngBounds | undefined {
  if (!coordinates || coordinates.length < 1) {
    return undefined;
  }
  const bounds = new LatLngBounds(coordinates[0]);
  for (let i = 1; i < coordinates.length; ++i) {
    bounds.extend(coordinates[i]);
  }
  return bounds;
}

export function getBoundingBox(
  mapElement: MapElement,
): LatLngBounds | undefined {
  const { geometry } = mapElement;
  if (geometry.type === 'Point') {
    return boundsFromCoordinates([geometry.coordinates]);
  }
  if (geometry.type === 'LineString') {
    return boundsFromCoordinates(geometry.coordinates);
  }
  if (geometry.type === 'Polygon') {
    return boundsFromCoordinates(geometry.coordinates[0]);
  }

  return undefined;
}
