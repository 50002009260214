import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private accessToken?: string;
  constructor(private readonly authService: AuthService) {
    this.authService.accessToken$.subscribe((token) => {
      this.accessToken = token;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      this.accessToken &&
      (request.url.startsWith(environment.backendUrl) ||
        request.url.startsWith(environment.mlBackendUrl))
    ) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${this.accessToken}` },
      });
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if (!(err instanceof HttpErrorResponse) || err.status !== 401) {
          throw err;
        }
        // Refresh access token and try again.
        return from(this.authService.refreshAccessToken()).pipe(
          switchMap((success) => {
            if (!success) {
              throw new HttpErrorResponse({
                status: 401,
                statusText: 'Need to login',
              });
            }
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${this.accessToken}` },
            });
            return next.handle(request);
          }),
        );
      }),
    );
  }
}
