import {
  EdgeElement,
  ElementType,
  GeoPoint,
  MapElement,
  NodeElement,
  RobotEdgeProperties,
  isEdge,
  isNode,
} from '@cartken/map-types';
import { MapElementManager } from '../map-elements/map-element-manager';
import {
  ClickEvent,
  GuideFeatureCollection,
  ModeProps,
  NonInteractiveFeatureCollection,
  Pick,
  TentativeFeature,
} from '../visualization/types';
import {
  getHandlesForPick,
  getUnderlyingFeaturePick,
} from '../visualization/utils';
import { InteractiveMode } from '../visualization/interactive-mode';
import { computeLength } from 'spherical-geometry-js';
import { hasAtLeastTwoElements } from '../../../utils/typeGuards';

export class CreateEdgeMode extends InteractiveMode {
  private startNodeId?: number;
  private endNodeId?: number;
  edgePoints: GeoPoint[] = [];

  constructor(private readonly mapElementManager: MapElementManager) {
    super();
  }

  override setActive(active: boolean) {
    if (active) {
      this.mapElementManager.selectedMapElement = undefined;
    } else {
      this.reset();
    }
  }

  override getGuides(props: ModeProps): GuideFeatureCollection {
    let lastCoords = props.lastHoverEvent?.mapCoords
      ? [props.lastHoverEvent?.mapCoords]
      : [];

    const guides: GuideFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    const hoveredPick = props.lastHoverEvent?.picks[0];
    const underlyingFeaturePick = getUnderlyingFeaturePick(hoveredPick, props);

    if (underlyingFeaturePick && isEdge(underlyingFeaturePick.object)) {
      guides.features.push(...getHandlesForPick(underlyingFeaturePick));
    }

    // Snap to node or edge vertex.
    if (
      hoveredPick &&
      (hoveredPick.object?.elementType === ElementType.NODE ||
        this.pickIsEdgeVertex(hoveredPick, props))
    ) {
      lastCoords = [hoveredPick.object.geometry.coordinates];
    }

    const coordinates = [...this.edgePoints, ...lastCoords];
    if (this.edgePoints.length > 0 && hasAtLeastTwoElements(coordinates)) {
      const tentativeFeature: TentativeFeature = {
        type: 'Feature',
        properties: {
          guideType: 'tentative',
        },
        geometry: {
          type: 'LineString',
          coordinates,
        },
      };
      guides.features.push(tentativeFeature);
    }

    return guides;
  }

  private pickIsEdgeVertex(hoveredPick: Pick, props: ModeProps) {
    const underlyingFeaturePick = getUnderlyingFeaturePick(hoveredPick, props);

    return (
      hoveredPick &&
      hoveredPick.isGuide &&
      underlyingFeaturePick &&
      isEdge(underlyingFeaturePick.object)
    );
  }

  private pickIsNode(pick: Pick | undefined) {
    return pick && pick.object && isNode(pick.object);
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    const hoveredPick = props.lastHoverEvent?.picks[0];
    if (!hoveredPick || !this.pickIsEdgeVertex(hoveredPick, props)) {
      return {
        type: 'FeatureCollection',
        features: [],
      };
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: hoveredPick!.object.geometry,
          properties: { lineColor: [0, 0xff, 0, 0xff], lineWidth: 5 },
        },
      ],
    };
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const pick = event.picks[0];
    const mapElementPick = getUnderlyingFeaturePick(pick, props);

    if (mapElementPick?.object) {
      this.onMapElementClick(
        mapElementPick?.object,
        pick.object?.properties?.positionIndexes?.[0],
      );
    } else {
      const [lng, lat, alt] = event.mapCoords;
      if (alt !== undefined) {
        this.edgePoints.push([lng, lat, alt]);
      } else {
        this.edgePoints.push([lng, lat]);
      }
    }
  }

  override onKeyUp(event: KeyboardEvent, props: ModeProps) {
    const { key } = event;
    if (key === 'Enter') {
      this.finishEdge();
    }
  }

  override onRightClick(event: ClickEvent, props: ModeProps) {
    this.finishEdge();
  }

  private startEdge(startPoint: GeoPoint) {
    this.mapElementManager.selectedMapElement = undefined;
    this.edgePoints.push(startPoint);
  }

  private finishEdge() {
    if (this.edgePoints.length < 2) {
      return;
    }

    const change: MapElement[] = [];
    if (!this.startNodeId) {
      const node = this.createNode(this.edgePoints[0]);
      change.push(node);
      this.startNodeId = node.id;
    }
    if (!this.endNodeId) {
      const node = this.createNode(this.edgePoints[this.edgePoints.length - 1]);
      change.push(node);
      this.endNodeId = node.id;
    }

    const edge = this.createEdge(this.edgePoints, {
      startNodeId: this.startNodeId,
      endNodeId: this.endNodeId,
      mutexIds: [],
    });

    change.push(edge);
    this.mapElementManager.addChange(change);
    this.mapElementManager.selectedMapElement = edge;
    this.reset();
  }

  private reset() {
    this.startNodeId = undefined;
    this.endNodeId = undefined;
    this.edgePoints = [];
  }

  private onMapElementClick(mapElement: MapElement, vertex?: number) {
    if (isNode(mapElement)) {
      this.onNodeClick(mapElement);
    }
    if (isEdge(mapElement) && vertex !== undefined) {
      this.onEdgeVertexClick(mapElement, vertex);
    }
  }

  private onNodeClick(node: NodeElement) {
    if (this.edgePoints.length) {
      if (node.id != this.startNodeId || this.edgePoints.length > 2) {
        this.endNodeId = node.id;
        this.edgePoints.push(node.geometry.coordinates);
        this.finishEdge();
      }
    } else {
      this.startNodeId = node.id;
      this.startEdge(node.geometry.coordinates);
    }
  }

  private onEdgeVertexClick(edge: EdgeElement, vertex: number) {
    if (this.edgePoints.length) {
      const node = this.splitEdge(edge, vertex);
      if (!node) {
        return;
      }
      const nodeCoords = node.geometry.coordinates;
      this.endNodeId = node.id;
      this.edgePoints.push(nodeCoords);
      this.finishEdge();
    } else {
      const node = this.splitEdge(edge, vertex);
      if (!node) {
        return;
      }
      this.startNodeId = node.id;
      this.startEdge(node.geometry.coordinates);
    }
  }

  private splitEdge(
    edge: EdgeElement,
    edgeVertexIndex: number,
  ): NodeElement | undefined {
    const coords = edge.geometry.coordinates;
    const splitCoords = coords[edgeVertexIndex];
    if (!splitCoords?.length) {
      return;
    }
    const deletedEdge = structuredClone(edge);
    deletedEdge.deleted = true;

    // Replace existing edge with new node and edges.
    const newNode = this.createNode(splitCoords);
    const edge1 = this.createEdge(coords.slice(0, edgeVertexIndex + 1), {
      ...structuredClone(edge.properties),
      endNodeId: newNode.id,
    });
    const edge2 = this.createEdge(
      coords.slice(edgeVertexIndex, coords.length),
      {
        ...structuredClone(edge.properties),
        startNodeId: newNode.id,
      },
    );
    this.mapElementManager.addChange([newNode, edge1, edge2, deletedEdge]);
    return newNode;
  }

  private createNode(coordinates: GeoPoint): NodeElement {
    return {
      id: this.mapElementManager.generateMapElementId(),
      version: this.mapElementManager.mapVersion() ?? 0,
      elementType: ElementType.NODE,
      geometry: {
        type: 'Point',
        coordinates,
      },
    };
  }

  private createEdge(
    coordinates: GeoPoint[],
    properties: Omit<RobotEdgeProperties, 'length'>,
  ): EdgeElement {
    if (!hasAtLeastTwoElements(coordinates)) {
      // prettier-ignore
      throw new Error(`Could not create an edge, edge does not have at least two coordinates. Got ${coordinates}`);
    }
    return {
      id: this.mapElementManager.generateMapElementId(),
      version: this.mapElementManager.mapVersion() ?? 0,
      elementType: ElementType.ROBOT_EDGE,
      properties: {
        ...properties,
        length: computeLength(coordinates),
      },
      geometry: {
        type: 'LineString',
        coordinates,
      },
    };
  }
}
