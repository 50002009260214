import { Component, Input, OnInit } from '@angular/core';
import { DataItem } from '../../data-viewer/data-viewer.service';
import { MlDataService } from '../../ml-data.service';
import { CollectionDto } from '../../ml-data-types';
import { MlActionService } from '../../ml-action.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MassActionProgressReport } from './mass-action-progress-bar.component';

@Component({
  selector: 'app-add-frames-to-collection',
  templateUrl: './add-frames-to-collection.component.html',
  styleUrls: ['./add-frames-to-collection.component.sass'],
})
export class AddFramesToCollectionComponent implements OnInit {
  @Input()
  frames: DataItem[] = [];

  progressReport: MassActionProgressReport | undefined;

  selectedCollectionIds = new Set<number>();
  collections: CollectionDto[] = [];

  constructor(
    readonly mlDataService: MlDataService,
    readonly mlActionService: MlActionService,
  ) {}

  async ngOnInit(): Promise<void> {
    const allCollections = await this.mlDataService.getCollections();
    this.collections = allCollections.filter(
      (collection) => !collection.locked,
    );
  }

  onChange(isChecked: boolean, collectionId: number): void {
    if (isChecked) {
      this.selectedCollectionIds.add(collectionId);
    } else {
      this.selectedCollectionIds.delete(collectionId);
    }
  }

  async onAddFrames() {
    this.progressReport = {
      totalCount: this.frames.length * this.selectedCollectionIds.size,
      successCount: 0,
      failureCount: 0,
      errorMessages: [],
    };
    const frames = this.frames;
    const collectionIds = Array.from(this.selectedCollectionIds);

    for (const frame of frames) {
      for (const collectionId of collectionIds) {
        try {
          await this.mlActionService.addFrameToCollection(
            collectionId,
            frame.id,
          );
          this.progressReport.successCount++;
        } catch (e) {
          console.error(e);
          this.progressReport.failureCount++;
          if (e instanceof HttpErrorResponse && 'error' in e.error) {
            this.progressReport.errorMessages.push(
              `Frame ${frame.id} - ${e.error.error}`,
            );
          }
        }
      }
    }
  }
}
