<div
  *ngIf="user.viewName === undefined"
  class="mat-elevation-z8 ops-overview-with-bottom-chip round-bottom"
  [class.absent-clocked-in-operator]="!!user.clockedInAt"
>
  <div class="ops-overview">
    <div class="overview-title">
      <img
        *ngIf="user.pictureUrl"
        class="profile-img"
        src="{{ user.pictureUrl }}"
      />
      {{ user.displayName }}

      <button
        mat-icon-button
        color="primary"
        aria-label="Access robot blackbox aggregation"
        style="pointer-events: auto"
        (click)="openBlackboxAggregation()"
      >
        <mat-icon>movie</mat-icon>
      </button>
    </div>
    <div class="update-time" *ngIf="user.latencyMillis">
      Since {{ user.since | prettyTime }}
      <hr class="splitter-line" />
    </div>
    <div
      class="update-time"
      *ngIf="!user.latencyMillis && user.since !== undefined"
    >
      Last seen {{ user.since | prettyTime }}
      <hr class="splitter-line" />
    </div>
    <div class="robot-grid">
      <div *ngFor="let robot of user.robots" class="robot-item">
        <img class="profile-img" src="{{ robot.pictureUrl }}" />
        Cart {{ robot.serialNumber }}
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="user.viewName !== undefined"
  class="mat-elevation-z8 round-bottom ops-overview-with-bottom-chip"
  [class.absent-clocked-in-operator]="
    !!user.clockedInAt && (user.viewName === 'Idle' || !user.latencyMillis)
  "
>
  <div class="ops-overview">
    <div class="user-title-container">
      <div
        class="overview-title"
        [routerLink]="robotUrlString"
        [class.user-with-robots]="robotUrlString !== undefined"
      >
        <img
          *ngIf="user.pictureUrl"
          class="profile-img"
          src="{{ user.pictureUrl }}"
        />
        {{ user.displayName }}

        <button
          mat-icon-button
          color="primary"
          aria-label="Access robot blackbox aggregation"
          style="pointer-events: auto"
          (click)="openBlackboxAggregation()"
        >
          <mat-icon>movie</mat-icon>
        </button>
      </div>
      <div class="app-version">{{ user.version }}</div>
    </div>
    <div class="update-time" *ngIf="user.latencyMillis">
      Since {{ user.since | prettyTime }}
      <hr class="splitter-line" />
    </div>
    <div class="update-time" *ngIf="!user.latencyMillis">
      Last seen {{ user.since | prettyTime }}

      <hr class="splitter-line" />
    </div>
    <div class="robot-grid">
      <div
        *ngFor="let robot of user.robots"
        class="robot-item"
        [class.robot-item-selected]="robot.isEnabledSlot"
        [class.robot-id-defined]="robot.robotId !== undefined"
        [routerLink]="'/robots/supervise/' + robot.robotId"
      >
        <ng-container *ngIf="robot.drivingState">
          <span
            [class]="
              'robot-driving-state-icon ' + robot.drivingState.toLowerCase()
            "
            [ngSwitch]="robot.drivingState"
          >
            <mat-icon *ngSwitchCase="'PARKING'" matTooltip="Parking and Waiting"
              >local_parking</mat-icon
            >
            <mat-icon
              *ngSwitchCase="'AUTONOMOUSLY_DRIVING'"
              matTooltip="Happily driving autonomously ☺️"
              >navigation</mat-icon
            >
            <mat-icon
              *ngSwitchCase="'MANUALLY_DRIVEN'"
              matTooltip="Manually driven by an Operator"
              >sports_esports</mat-icon
            >
            <mat-icon
              *ngSwitchCase="'BLOCKED'"
              matTooltip="Blocked by someone or something 😟"
              >pan_tool</mat-icon
            >
            <mat-icon
              *ngSwitchCase="'UNKNOWN'"
              matTooltip="Something went wrong 😳"
              >question_mark</mat-icon
            >
          </span>
        </ng-container>
        <span> {{ robot.displayName }} </span>
        <mat-icon
          *ngIf="user.viewName === 'ManagedSupervision' && robot.robotId"
          class="unassign-robot"
          (click)="
            unassignRobot(robot.robotId, user.userId); $event.stopPropagation()
          "
          >clear</mat-icon
        >
      </div>
    </div>

    <mat-form-field appearance="fill" class="select-access-groups">
      <mat-label>Select access groups</mat-label>
      <mat-select
        placeholder="Select access groups"
        multiple
        [(ngModel)]="user.selectedAccessGroups"
        (selectionChange)="updatedSelectedAccessGroups()"
      >
        <mat-option
          *ngFor="let accessGroup of user.accessGroups"
          [value]="accessGroup"
          >{{ accessGroup }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="user-log-data round-bottom">
    <span class="view-name">
      {{ user.viewName | viewName }}
    </span>

    <span class="indicators">
      <span *ngIf="user.speedSum !== 0" class="indicator-item">
        <mat-icon>speed</mat-icon>
        {{ user.speedSum | number: '1.0-1' }}km/h
      </span>
      <span *ngIf="user.latencyMillis" class="indicator-item">
        <mat-icon>network_ping</mat-icon>
        {{ user.latencyMillis }}ms
      </span>
    </span>
  </div>
</div>
