import * as v from 'valibot';
import * as P from './point-geometry';
import * as E from './edge';
import * as T from './polygon';

const M = { ...T, ...P, ...E };

export type PointGeometryElement = v.InferOutput<typeof PointGeometryElement>;
export const PointGeometryElement = v.variant('elementType', Object.values(P));

export type EdgeElement = v.InferOutput<typeof EdgeElement>;
export const EdgeElement = v.variant('elementType', Object.values(E));

export type MapElement = v.InferOutput<typeof MapElement>;
export const MapElement = v.variant('elementType', Object.values(M));

export * from './point-geometry';
export * from './edge';
export * from './polygon';
export * from './base';
export * from './properties';
export * from './transient';
