import { _Tileset2D } from '@deck.gl/geo-layers';
import { Viewport } from '@deck.gl/core';
import { getTileIndices, tileToBoundingBox, TileIndex } from './tileset-utils';
import {
  Bounds,
  Tileset2DProps,
  ZRange,
} from '@deck.gl/geo-layers/dist/tileset-2d';

export type LocalizationTilesetParams = _LocalizationTilesetProps &
  Tileset2DProps;

export type _LocalizationTilesetProps = {
  /** [Lng,Lat] of the ENU coordinate origin */
  enuCoordinateOrigin: [number, number];
};

export class LocalizationTileset extends _Tileset2D {
  constructor(private params: LocalizationTilesetParams) {
    super(params);
    if (!this.params.enuCoordinateOrigin) {
      this.params.enuCoordinateOrigin = [
        this.params.extent?.[0] ?? 0,
        this.params.extent?.[1] ?? 0,
      ];
    }
  }

  override getTileIndices({
    viewport,
    maxZoom,
    minZoom,
    zRange,
  }: {
    viewport: Viewport;
    maxZoom?: number;
    minZoom?: number;
    zRange: ZRange;
    tileSize?: number;
    zoomOffset?: number;
  }): TileIndex[] {
    const { extent, tileSize } = this.params;
    return getTileIndices({
      viewport,
      maxZoom,
      minZoom,
      zRange,
      extent: extent as Bounds | null | undefined,
      tileSize,
    });
  }

  override getTileId(index: TileIndex) {
    return `${index.x}-${index.y}`;
  }

  override getTileMetadata(index: TileIndex): Record<string, any> {
    const { tileSize, enuCoordinateOrigin } = this.params;
    return {
      bbox: tileToBoundingBox(
        index,
        enuCoordinateOrigin ?? [0, 0],
        tileSize ?? 50,
      ),
    };
  }
}
