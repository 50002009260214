import { ElementRef } from '@angular/core';
import { RoutesService } from '../../core/route-service';
import {
  RouteDto,
  RouteVertexDto,
} from '../../core/robots-service/backend/types';
import {
  DEFAULT_NON_INTERACTIVE_FEATURES,
  InteractiveMode,
} from '../visualization/interactive-mode';
import { VisualizationManager } from '../visualization/visualization-manager';
import {
  ClickEvent,
  ModeProps,
  NonInteractiveFeatureCollection,
} from '../visualization/types';
import { firstValueFrom } from 'rxjs';
import { GeoPoint } from '@cartken/map-types';
import { hasAtLeastTwoElements } from '../../../utils/typeGuards';

export class RoutingMode extends InteractiveMode {
  routeInfo?: string;
  limitToOperationId?: string;
  fromLocation?: GeoPoint;
  toLocation?: GeoPoint;
  fromLocationElement?: ElementRef;
  toLocationElement?: ElementRef;
  private routeVisualization: NonInteractiveFeatureCollection =
    DEFAULT_NON_INTERACTIVE_FEATURES;

  constructor(
    private readonly visualizationManager: VisualizationManager,
    private readonly routesService: RoutesService,
  ) {
    super();
  }

  override setActive(active: boolean, subMode?: string | undefined): void {
    if (active) {
      this.visualizationManager.setMapElementOpacity(0.1);
    } else {
      this.visualizationManager.setMapElementOpacity(1);
    }
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    return this.routeVisualization;
  }

  setFromLocationElement(fromLocationElement: ElementRef) {
    this.fromLocationElement = fromLocationElement;
    const bounds = this.visualizationManager.getLatLngBounds();
    const options: google.maps.places.AutocompleteOptions = {
      bounds: new google.maps.LatLngBounds(
        { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
        { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
      ),
    };
    const fromAutocomplete = new google.maps.places.Autocomplete(
      fromLocationElement.nativeElement,
      options,
    );

    fromAutocomplete.addListener('place_changed', () => {
      this.fromLocation = this.getLocationFromPlaceOrLatLng(
        fromAutocomplete.getPlace(),
        fromLocationElement,
      );
      this.updateRoute();
    });
  }

  setToLocationElement(toLocationElement: ElementRef) {
    this.toLocationElement = toLocationElement;
    const bounds = this.visualizationManager.getLatLngBounds();
    const options: google.maps.places.AutocompleteOptions = {
      bounds: new google.maps.LatLngBounds(
        { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
        { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
      ),
    };
    const toAutocomplete = new google.maps.places.Autocomplete(
      toLocationElement.nativeElement,
      options,
    );

    toAutocomplete.addListener('place_changed', () => {
      this.toLocation = this.getLocationFromPlaceOrLatLng(
        toAutocomplete.getPlace(),
        toLocationElement,
      );
      this.updateRoute();
    });
  }

  private getRouteInfo(route: RouteDto): string {
    const distance =
      route.distance >= 1000
        ? (route.distance / 1000).toFixed(1) + 'km'
        : route.distance.toFixed(0) + 'm';
    const duration =
      route.duration >= 60
        ? (route.duration / 60).toFixed(0) + 'min'
        : route.duration.toFixed(0) + 'sec';
    return `Distance: ${distance}, Duration: ${duration}`;
  }

  private createRouteVisualization(route: RouteVertexDto[]) {
    const coordinates = route.map((point): GeoPoint => {
      return [point.longitude, point.latitude, (point.altitude ?? 0) + 0.001];
    });
    if (!hasAtLeastTwoElements(coordinates)) {
      // prettier-ignore
      throw new Error(`Route has less than two elements, got ${coordinates}`)
    }
    this.routeVisualization = {
      type: 'FeatureCollection',
      features: [
        {
          geometry: {
            type: 'LineString',
            coordinates,
          },
          properties: {
            lineColor: [0x7f, 0x7f, 0xff, 0xff],
            lineWidth: 7,
          },
        },
      ],
    };
  }

  async updateRoute() {
    if (!this.fromLocation || !this.toLocation) {
      this.routeVisualization = DEFAULT_NON_INTERACTIVE_FEATURES;
      this.visualizationManager.rerenderMapElements();
      return;
    }
    try {
      const routeDto = await firstValueFrom(
        this.routesService.getRoute(
          this.fromLocation,
          this.toLocation,
          this.limitToOperationId,
        ),
      );

      if (!routeDto?.geometry) {
        this.routeInfo = 'Routing failed';
        return;
      }
      this.routeInfo = this.getRouteInfo(routeDto);
      this.createRouteVisualization(routeDto.geometry);
      this.visualizationManager.rerenderMapElements();
    } catch (e) {
      this.routeInfo = `Routing failed: ${e}`;
    }
  }

  private parseLatLngAltString(latLonString: string): GeoPoint | undefined {
    const latLngAltString = latLonString.split(/, ?/);
    if (latLngAltString.length !== 2 && latLngAltString.length !== 3) {
      return undefined;
    }
    const [lat, lng, alt] = latLngAltString.map(Number.parseFloat);
    return [lng, lat, alt];
  }

  private getLocationFromPlaceOrLatLng(
    place: google.maps.places.PlaceResult,
    locationElement: ElementRef,
  ): GeoPoint | undefined {
    const latLonAlt = this.parseLatLngAltString(
      locationElement.nativeElement.value,
    );
    if (latLonAlt) {
      return latLonAlt;
    }
    if (!place.geometry?.location) {
      return undefined;
    }
    const point = place.geometry.location;
    return [point.lng(), point.lat()];
  }

  formatLatLngAltString([lng, lat, alt]: GeoPoint) {
    return alt === undefined ? `${lat}, ${lng}, ${alt}` : `${lat}, ${lng}`;
  }

  override onLeftClick({ mapCoords }: ClickEvent, props: ModeProps): void {
    if (this.fromLocationElement) {
      this.fromLocationElement.nativeElement.value =
        this.formatLatLngAltString(mapCoords);
    }
    this.fromLocation = mapCoords;
    this.updateRoute();
  }

  override onRightClick({ mapCoords }: ClickEvent, props: ModeProps): void {
    if (this.toLocationElement) {
      this.toLocationElement.nativeElement.value =
        this.formatLatLngAltString(mapCoords);
    }
    this.toLocation = mapCoords;
    this.updateRoute();
  }
}
