import { GeoPoint } from '@cartken/map-types';
import { Feature } from './geojson-types';

export function assumeFeatureAltitudes(
  feature: Feature,
  assumeAltitude: (point: GeoPoint) => [number, number, number],
) {
  const geometry = structuredClone(feature.geometry);
  switch (geometry.type) {
    case 'Point':
      geometry.coordinates = assumeAltitude(geometry.coordinates);
      break;
    case 'LineString':
    case 'MultiPoint':
      geometry.coordinates = geometry.coordinates.map(assumeAltitude);
      break;
    case 'Polygon':
    case 'MultiLineString':
      geometry.coordinates = geometry.coordinates.map(
        (p) => p.map(assumeAltitude) as [GeoPoint, GeoPoint, ...GeoPoint[]],
      );
      break;
    case 'MultiPolygon':
      geometry.coordinates = geometry.coordinates.map(
        (a) =>
          a.map((p) => p.map(assumeAltitude)) as [
            [GeoPoint, GeoPoint, GeoPoint, ...GeoPoint[]],
            ...[GeoPoint, GeoPoint, GeoPoint, ...GeoPoint[]][],
          ],
      );
      break;
  }
  return {
    ...feature,
    geometry,
  };
}
