import * as v from 'valibot';
import { GeoPoint } from './geo-point';

export const PointGeometry = v.object({
  type: v.literal('Point'),
  coordinates: GeoPoint,
});
export type PointGeometry = v.InferOutput<typeof PointGeometry>;

export const LineStringGeometry = v.object({
  type: v.literal('LineString'),
  coordinates: v.tupleWithRest([GeoPoint, GeoPoint], GeoPoint),
});
export type LineStringGeometry = v.InferOutput<typeof LineStringGeometry>;

const Polygon = v.tupleWithRest([GeoPoint, GeoPoint, GeoPoint], GeoPoint);

export const PolygonGeometry = v.object({
  type: v.literal('Polygon'),
  coordinates: v.tupleWithRest([Polygon], Polygon),
});
export type PolygonGeometry = v.InferOutput<typeof PolygonGeometry>;
