import { Component } from '@angular/core';
import { ClockingService } from './clocking.service';

@Component({
  selector: 'app-clocking',
  templateUrl: './clocking.component.html',
  styleUrl: './clocking.component.sass',
})
export class ClockingComponent {
  constructor(public clockingService: ClockingService) {}
}
