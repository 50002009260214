import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, lastValueFrom, Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { MetricsService } from '../core/metrics/metrics.service';
import { RobotCommunication } from '../core/robots-service/robot-communication';
import { UserSessionService } from '../core/user-session/user-session.service';
import { PageVisibilityWatchDog } from './page-visibility-watch-dog';
import { SupervisedRobotSlotsConfigService } from './supervised-robots-slots.service';
import { SupervisionSettingsDialog } from './supervision-settings/supervision-settings.component';
import { RobotSlotCell, RobotSlots } from './supervision-slot';
import { ViewName } from '../core/user-session/user-session.utils';
import { UserSessionEventTrackingService } from '../core/user-session/user-session-event-tracking.service';
import { UserSessionSystemEventName } from '../core/user-session/user-session-system-events';
import { ConfirmationDialog } from '../core/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from './robot-supervision/skip-robot-dialog.component';
import { ClockingService } from '../core/clocking/clocking.service';

const PAGE_INVISIBLE_STOPPING_TIMEOUT_MILLIS = 15 * 1000;

@Component({
  selector: 'app-supervise-robots',
  templateUrl: './supervised-robots.component.html',
  styleUrls: ['./supervised-robots.component.sass'],
})
export class SupervisedRobotsComponent implements OnInit, OnDestroy {
  private readonly userSessionService = inject(UserSessionService);

  robotCommunicationInFocus?: RobotCommunication;
  robotSlots$: Observable<RobotSlots>;
  pingCount$ = this.userSessionService.pingCount$;

  private _destroy = new Subject();

  private pageVisibilityWatchDog = new PageVisibilityWatchDog(
    PAGE_INVISIBLE_STOPPING_TIMEOUT_MILLIS,
    async () => {
      if (this.robotCommunicationInFocus === undefined) {
        await this.supervisedRobotSlotsConfigService.stopSupervision();
        this.userSessionService.goOffline();
      } else {
        this.robotCommunicationInFocus.enableAutonomy(false);
      }
    },
    async () => {
      if (this.robotCommunicationInFocus === undefined) {
        this.snackBar.open(
          'Welcome back online, please do not leave robots unattended',
          undefined,
          { duration: 3000, verticalPosition: 'top' },
        );
        this.supervisedRobotSlotsConfigService.startSupervision();
        await this.userSessionService.goOnline();
        this.userSessionService.setViewName(ViewName.MANAGED_SUPERVISION);
      }
    },
  );

  constructor(
    private readonly supervisedRobotSlotsConfigService: SupervisedRobotSlotsConfigService,
    private readonly metricsService: MetricsService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
    private readonly clockingService: ClockingService,
  ) {
    this.robotSlots$ = this.supervisedRobotSlotsConfigService.robotSlots$.pipe(
      shareReplay(1),
    );

    this.robotSlots$.pipe(takeUntil(this._destroy)).subscribe((slots) => {
      const robotsCount = slots.filter(
        (slot) => slot.slotType === 'taken',
      ).length;

      this.metricsService.putCurrentRobotCount(robotsCount);
    });
  }

  async ngOnDestroy(): Promise<void> {
    await this.supervisedRobotSlotsConfigService.stopSupervision();
    this.userSessionService.goOffline();
    this.pageVisibilityWatchDog.destroy();
    this.userSessionEventTrackingService.trackSystemEvent(
      UserSessionSystemEventName.STOP_ROBOT_CONTROL,
    );
    this._destroy.next(undefined);
  }

  async ngOnInit(): Promise<void> {
    const clockedInAt = await firstValueFrom(this.clockingService.clockedInAt$);
    if (!clockedInAt) {
      const clockIn = await lastValueFrom(
        this.dialog
          .open<ConfirmationDialog, ConfirmationDialogData>(
            ConfirmationDialog,
            {
              data: {
                message: 'You are not clocked in, clock in?',
              },
            },
          )
          .afterClosed(),
      );
      if (clockIn) {
        await this.clockingService.clockIn();
      }
    }

    this.supervisedRobotSlotsConfigService.startSupervision();

    await this.userSessionService.goOnline();
    this.userSessionService.setViewName(ViewName.MANAGED_SUPERVISION);

    this.userSessionService.sessionCollision$
      .pipe(takeUntil(this._destroy))
      .subscribe(async () => {
        await this.supervisedRobotSlotsConfigService.stopSupervision();
        this.userSessionService.goOffline();
      });
    this.userSessionEventTrackingService.trackSystemEvent(
      UserSessionSystemEventName.START_ROBOT_CONTROL,
    );
    this.pageVisibilityWatchDog.start();
  }

  async focusRobotView(robotCommunicationInFocus: RobotCommunication) {
    this.userSessionService.setViewName(ViewName.FOCUSED_SUPERVISION);
    await this.supervisedRobotSlotsConfigService.stopSupervision(
      robotCommunicationInFocus,
    );
    this.robotCommunicationInFocus = robotCommunicationInFocus;
  }

  unfocusRobotView() {
    this.supervisedRobotSlotsConfigService.startSupervision();
    this.userSessionService.setViewName(ViewName.MANAGED_SUPERVISION);
    this.robotCommunicationInFocus = undefined;
  }

  openSupervisionSettings() {
    this.dialog.open(SupervisionSettingsDialog);
  }

  trackByIndex(index: number, robotCell: RobotSlotCell) {
    // it is necessary so, that angular correctly associate DOM objects with relevant elements in an array
    // otherwise, it uses equality and it was causing flaky behavior
    if (robotCell.slotType === 'taken') {
      return robotCell.robotCommunication;
    }
    return index;
  }
}
