import { Component, Input } from '@angular/core';

export interface MassActionProgressReport {
  successCount: number;
  failureCount: number;
  totalCount: number;
  errorMessages: string[];
}

@Component({
  selector: 'app-mass-action-progress-bar',
  templateUrl: './mass-action-progress-bar.component.html',
  styleUrls: ['./mass-action-progress-bar.component.sass'],
})
export class MassActionProgressBarComponent {
  @Input() progressReport: MassActionProgressReport | undefined;
}
