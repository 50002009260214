import * as v from 'valibot';

export const vParsePretty: typeof v.parse = <
  const TSchema extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>,
>(
  schema: TSchema,
  input: unknown,
  config?: v.Config<v.InferIssue<TSchema>>,
) => {
  const result = v.safeParse(schema, input, config);
  if (!result.success) {
    console.error(v.flatten(result.issues));
    throw new Error(`Validation failed, see details above.`);
  }
  return result.output;
};
