import * as v from 'valibot';
import { LineStringGeometry } from '../geometry';
import { mapElementType, ElementType } from './base';
import {
  RoadEdgeProperties,
  MovablePlatformEdgeProperties,
  RobotEdgeProperties,
  RobotQueueEdgeProperties,
} from './properties';

export const CachedRoadEdge = mapElementType(
  ElementType.CACHED_ROAD_EDGE,
  RoadEdgeProperties,
  LineStringGeometry,
);
export type CachedRoadEdge = v.InferOutput<typeof CachedRoadEdge>;

export const InfrastructureEdge = mapElementType(
  ElementType.INFRASTRUCTURE_EDGE,
  RobotEdgeProperties,
  LineStringGeometry,
);
export type InfrastructureEdge = v.InferOutput<typeof InfrastructureEdge>;

export const InfrastructureWaitingEdge = mapElementType(
  ElementType.INFRASTRUCTURE_WAITING_EDGE,
  RobotEdgeProperties,
  LineStringGeometry,
);
export type InfrastructureWaitingEdge = v.InferOutput<
  typeof InfrastructureWaitingEdge
>;

export const MovablePlatformEdge = mapElementType(
  ElementType.MOVABLE_PLATFORM_EDGE,
  MovablePlatformEdgeProperties,
  LineStringGeometry,
);
export type MovablePlatformEdge = v.InferOutput<typeof MovablePlatformEdge>;

export const RoadEdge = mapElementType(
  ElementType.ROAD_EDGE,
  RoadEdgeProperties,
  LineStringGeometry,
);
export type RoadEdge = v.InferOutput<typeof RoadEdge>;

export const RobotEdge = mapElementType(
  ElementType.ROBOT_EDGE,
  RobotEdgeProperties,
  LineStringGeometry,
);
export type RobotEdge = v.InferOutput<typeof RobotEdge>;

export const RobotQueueEdge = mapElementType(
  ElementType.ROBOT_QUEUE_EDGE,
  RobotQueueEdgeProperties,
  LineStringGeometry,
);
export type RobotQueueEdge = v.InferOutput<typeof RobotQueueEdge>;
