import { MapElementManager } from '../map-elements/map-element-manager';
import {
  ReachableEdge,
  computeAllReachableEdges,
} from './reachable-edges-algorithms';
import {
  ElementType,
  isNode,
  RobotEdgeProperties,
  RobotQueueEdgeProperties,
} from '@cartken/map-types';
import {
  DEFAULT_NON_INTERACTIVE_FEATURES,
  InteractiveMode,
} from '../visualization/interactive-mode';
import {
  ClickEvent,
  ModeProps,
  NonInteractiveFeatureCollection,
} from '../visualization/types';
import { zOffsetGeometry } from './utils';
import { VisualizationManager } from '../visualization/visualization-manager';

export class ReachableEdgesMode extends InteractiveMode {
  private reachableEdgeVisualizations: NonInteractiveFeatureCollection =
    DEFAULT_NON_INTERACTIVE_FEATURES;

  private _maxDistance = 1500; // [m]
  private _autonomyOnly = false;
  private _startNodeId?: number;

  constructor(
    private readonly mapElementManager: MapElementManager,
    private readonly visualizationManager: VisualizationManager,
  ) {
    super();
  }

  set autonomyOnly(enabled: boolean) {
    this._autonomyOnly = enabled;
    this.updateReachableEdges();
  }

  get autonomyOnly() {
    return this._autonomyOnly;
  }

  set maxDistance(distance: number) {
    this._maxDistance = distance;
    this.updateReachableEdges();
  }

  get maxDistance() {
    return this._maxDistance;
  }

  setStartNodeId(nodeId?: number) {
    this._startNodeId = nodeId;
    this.updateReachableEdges();
  }

  override setActive(active: boolean, subMode?: string | undefined): void {
    if (active) {
      this.setStartNodeId(undefined);
    }
  }

  override getNonInteractiveFeatures(
    props: ModeProps,
  ): NonInteractiveFeatureCollection {
    return this.reachableEdgeVisualizations;
  }

  override onLeftClick(event: ClickEvent, props: ModeProps) {
    const node = event.picks[0]?.object;
    if (!isNode(node)) {
      this.setStartNodeId(undefined);
      return;
    }
    this.setStartNodeId(node.id);
  }

  private updateReachableEdges() {
    if (this._startNodeId === undefined) {
      this.reachableEdgeVisualizations = DEFAULT_NON_INTERACTIVE_FEATURES;
      this.visualizationManager.rerenderMapElements();
      return;
    }
    const robotNodeGraph = new Map<number, Array<number>>(); // <nodeId: [RobotEdgeIds]>
    for (const [_, edge] of this.mapElementManager.getMapElements()) {
      if (
        edge.deleted === true ||
        (edge.elementType !== ElementType.INFRASTRUCTURE_EDGE &&
          edge.elementType !== ElementType.INFRASTRUCTURE_WAITING_EDGE &&
          edge.elementType !== ElementType.MOVABLE_PLATFORM_EDGE &&
          edge.elementType !== ElementType.ROBOT_EDGE &&
          edge.elementType !== ElementType.ROBOT_QUEUE_EDGE)
      ) {
        continue;
      }
      const p = edge.properties;
      robotNodeGraph.set(p.startNodeId, [
        ...(robotNodeGraph.get(p.startNodeId) ?? []),
        edge.id,
      ]);

      robotNodeGraph.set(p.endNodeId, [
        ...(robotNodeGraph.get(p.endNodeId) ?? []),
        edge.id,
      ]);
    }

    const reachableEdges = computeAllReachableEdges(
      this._startNodeId,
      this.mapElementManager.getMapElements(),
      robotNodeGraph,
      this._maxDistance,
      this._autonomyOnly,
    );

    this.reachableEdgeVisualizations = {
      type: 'FeatureCollection',
      features: [],
    };
    for (const reachableEdge of reachableEdges) {
      this.createReachableEdgeVisualization(reachableEdge);
    }
    this.visualizationManager.rerenderMapElements();
  }

  private createReachableEdgeVisualization(reachableEdge: ReachableEdge) {
    const normalizedDistance = reachableEdge.distance / this._maxDistance;
    this.reachableEdgeVisualizations.features.push({
      type: 'Feature',
      geometry: zOffsetGeometry(
        { type: 'LineString', coordinates: reachableEdge.coordinates },
        0.001,
      ),
      properties: {
        lineColor: [
          0,
          255 * (1 - normalizedDistance),
          255 * normalizedDistance,
          255,
        ],
        lineWidth: 9,
      },
    });
  }
}
