import { Component, Input, OnChanges } from '@angular/core';
import { isDefined } from '../../utils/typeGuards';
import { UserSessionService } from '../core/user-session/user-session.service';
import { RobotDrivingState } from '../../utils/robots';
import { ViewName } from '../core/user-session/user-session.utils';
import { Router } from '@angular/router';

export type RobotCardData = {
  displayName?: string;
  pictureUrl?: string;
  isEnabledSlot?: boolean;
  drivingState?: RobotDrivingState;
  robotId?: string;
  operationId?: string;
};

export type UserCardData = {
  viewName?: ViewName;
  since?: Date;
  latencyMillis?: number;
  pictureUrl?: string;
  displayName: string;
  robots: RobotCardData[];
  speedSum?: number;
  version: string;
  selectedAccessGroups: string[];
  accessGroups: string[];
  userId: string;
  clockedInAt?: Date;
};

@Component({
  selector: 'app-robot-operator-info-card',
  templateUrl: './robot-operator-info-card.component.html',
  styleUrls: ['./robot-operator-info-card.component.sass'],
})
export class RobotOperatorInfoCardComponent implements OnChanges {
  @Input()
  user!: UserCardData;

  constructor(
    private userSessionService: UserSessionService,
    private router: Router,
  ) {}

  robotUrlString?: string;

  ngOnChanges(): void {
    const robotsIds = this.user.robots
      .map(({ robotId }) => robotId)
      .filter(isDefined);
    if (robotsIds.length > 0) {
      this.robotUrlString = `/robots/supervise/${robotsIds.join(',')}`;
    } else {
      this.robotUrlString = undefined;
    }
  }

  unassignRobot(robotId: string, userId: string) {
    this.userSessionService.unassignOperator(userId, [robotId]);
    this.user.robots = this.user.robots.filter(
      (robot) => robot.robotId !== robotId,
    );
  }

  updatedSelectedAccessGroups() {
    this.userSessionService.selectOperatorAccessGroups(
      this.user.userId,
      this.user.selectedAccessGroups ?? [],
    );
  }

  openBlackboxAggregation() {
    this.router.navigate(['operator/blackbox', this.user.userId]);
  }
}
