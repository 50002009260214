import {
  RobotIssue,
  RobotIssueSeverity,
} from '../../robot-issues/robot-issue.types';

export enum CompartmentState {
  OPEN = 'Open',
  OPEN_AND_PRIMED = 'OpenAndPrimed',
  CLOSED_AND_UNLOCKED = 'ClosedAndUnlocked',
  CLOSED_AND_LOCKED = 'ClosedAndLocked',
}

export interface Location {
  latitude: number;
  longitude: number;
}

export enum HazardLightsState {
  AUTO = 'Auto',
  HAZARD = 'Hazard',
  DEFAULT = 'Default',
  NOTIFY_LOW_INTENSITY = 'Notify_Low_Intensity',
  NOTIFY_HIGH_INTENSITY = 'Notify_High_Intensity',
  ALARM = 'Alarm',
}

export enum PowerMode {
  DEFAULT = 'Default',
  SAVING = 'Saving',
}

export enum LocalizationDataRecordingState {
  READY = 'Ready',
  RECORDING = 'Recording',
}

type AssignedOrderReference = {
  id: string;
  handoverToCompleteIndex?: number;
};

export interface ComponentVersion {
  componentName?: string;
  majorVersion?: number;
  minorVersion?: number;
  patchVersion?: number;
  versionAppendix?: string;
}

export interface ScheduledStop {
  latitude: number;
  longitude: number;
  altitude?: number;
  robotQueueId?: number;
  infrastructureId?: number;
  estimatedArrivalTime: Date;
  estimatedWaitingDuration: number;
  orders: AssignedOrderReference[];
}

export interface UberPropsDto {
  vehicleId?: string;
  isEnabled?: boolean;
}

export interface ConnectedRobotOperatorDto {
  id: string;
  displayName?: string;
  connectedInterface?: string;
}
export interface Compartment {
  id: string;
  displayName?: string;
  state?: CompartmentState;
  orderDetected?: boolean;
}

export enum RecordingStatus {
  IDLE = 'Idle',
  PREPARED_TO_START = 'PreparedToStart',
  STARTING = 'Starting',
  RECORDING = 'Recording',
  STOPPING = 'Stopping',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
}

interface MappingStatusDto {
  startDistance?: number;
  startTime?: string;
  minDistanceMeters?: number;
  targetDistanceMeters?: number;
  timeoutMillis?: number;
  recordingStatus: RecordingStatus;
}

export enum ControlType {
  AUTONOMY = 'Autonomy',
  GOVERNOR = 'Governor',
  RC = 'RC',
  SUPERVISOR = 'Supervisor',
  WEB_RTC = 'WebRTC',
}

export interface UserClaim {
  claimedBy: string;
  reason?: string;
  claimedUntil?: Date;
  claimedSince?: Date;
}

export enum InfrastructureTransactionStatus {
  ACCESS_REQUESTED = 'AccessRequested',
  WAITING_SPOT_ASSIGNED = 'WaitingSpotAssigned',
  ENTER_REQUESTED = 'EnterRequested',
  ENTERED = 'Entered',
  EXIT_REQUESTED = 'ExitRequested',
  ERROR = 'Error',
}

export interface InfrastructureTransaction {
  status?: InfrastructureTransactionStatus;
  infrastructureId?: number;
  locationName?: string;
  abortedWithError?: string;
  updatedAt?: string;
}
export interface UnsupervisedAutonomyState {
  available?: boolean;
  active?: boolean;
  remainingDistance?: number;
  abortReport?: string;
  abortStamp?: Date;
}

export class RobotRouteMatch {
  edgeId?: number;
  distanceAlongRoute?: number;
  distanceToCrossing?: number;
  onCrossing?: boolean;
  currentOrUpcomingCrossingId?: number;
}

export interface RobotBase {
  id: string;
  displayName: string;
  assignedOperationId?: string;
  readyForOrders?: boolean;
  accessGroups?: string[];
  pictureUrl?: string;
  shortName?: string;
  displayColor?: string;
  priority?: number;
  location: Location;
  arrivedAtStop?: boolean;
  arrivedAtStopManuallySetByUserId?: string;
  infrastructureTransaction?: InfrastructureTransaction;
  heading: number;
  velocity: number;
  absoluteTraveledDistance?: number;
  hazardLightsState?: HazardLightsState;
  desiredOperatorHazardLightsState?: HazardLightsState;
  robotView?: Buffer;
  scheduledStops?: ScheduledStop[];
  assignedOrderIds: string[];
  batteryVoltage?: number;
  batteryPercentage?: number;
  isCharging?: boolean;
  isVirtualRobot?: boolean;
  stateReport?: string;
  warningReports?: string[];
  automaticPowerSaving?: boolean;
  errorReports?: string[];
  attentionReports?: string[];
  orderDetected?: boolean;
  powerMode?: PowerMode;
  componentVersions?: ComponentVersion[];
  awxUpdateActive?: boolean;
  lastAwxUpdateSuccessful?: boolean;
  integrationUber?: UberPropsDto;
  controllingRobotOperatorId?: string;
  connectedRobotOperators?: ConnectedRobotOperatorDto[];
  controlType?: ControlType;
  serialNumber: number;
  issues?: RobotIssue[];
  highestIssueSeverity?: RobotIssueSeverity;
  compartments?: Compartment[];
  localizationDataRecordingState?: LocalizationDataRecordingState;
  userClaim?: UserClaim;
  stopState?: {
    isBlocked: boolean;
    stoppedSince: string;
  };
  gpsCovariance?: number;
  jetsonUptime?: number;
  mappingStatus?: MappingStatusDto;
  unsupervisedAutonomyState?: UnsupervisedAutonomyState;
  operationEmergencyStopActive?: boolean;
  routeMatch?: RobotRouteMatch;
}

export interface RobotDto extends RobotBase {
  updatedAt?: string;
  autonomyEnabledUntil?: string;
}

export interface RobotState extends RobotBase {
  updatedAt?: Date;
  autonomyEnabledUntil?: Date;
}

export function getBatteryHours(batteryPercentage?: number): number {
  return ((batteryPercentage ?? 0) * 14) / 100;
}
