export class RobotConnectionStats {
  timestampMs = 0;
  fps = 0;
  frameWidth = 0;
  frameHeight = 0;
  receivedVideoBitsPerSecond = 0;
  receivedBitsPerSecond = 0;
  sentBitsPerSecond = 0;

  totalFrames = 0;
  totalReceivedVideoBytes = 0;
  totalReceivedBytes = 0;
  totalSentBytes = 0;

  requestedFps = 0;
  requestedMaxBitrate = 0;
  requestedMaxPixelCount = 0;
}

export type VideoChannelConfiguration = {
  channelName?: VideoChannel;
  maxPixelCount?: number;
  fps?: number;
  maxBitrate?: number;
};

export type MapDataPreprocessingActions = 'finish' | 'start';

type WebRtcCommands = {
  revokeRouteCorridorConfirmation: {};
  confirmRouteCorridorEdgeId: number;
  autonomyEnabled: { autonomyEnabledFor: number };
  unsupervisedAutonomyAbortReportHandled: {};
  turnUpHighBeams: boolean;
  tareScale: boolean;
  powerCycle: {};
  hubReset: {};
  estopRelease: {};
  odriveReset: {}; // TODO(jonas): Remove when all robots have a release >= 2.7.0
  drivetrainReset: {};
  mapDataPreprocessorRequest: MapDataPreprocessingActions;
  lidLocked: boolean;
  path_corridor: {
    action: 'add' | 'reset' | 'stop';
    x: number;
    y: number;
    width: number;
  };
  obstacleOverride: {};
  updateTriggerServiceConfig: unknown;
  triggerDataCollectionSnapshot: {};
  triggerSnapshot: {};
  relocalize: {};
  dataUploaderAddDataBudget: {
    interfaceName: string;
    dataBudgetBytes: number;
  };
  dataUploaderResetDataBudget: {
    interfaceName: string;
  };
  customGpsLocation: {
    latitude: number;
    longitude: number;
  };
  endstopCommand: EndstopState;
  imageCommand: ImageCommand;
  sirenRequest: 'on' | 'off';
  manualControl: ManualRobotControl;
  openCompartment: string;
  closeCompartment: string;
};

export type WebRtcLabels = keyof WebRtcCommands;

export type WebRtcSendMessage<K extends WebRtcLabels> =
  | {
      label: K;
      payload: WebRtcCommands[K];
    }
  | { claimControl: true }
  | { releaseControl: true }
  | { videoConfig: VideoChannelConfiguration }
  | { pong: number }
  | { label: string; streamEnabled: boolean }
  | { videoStreamEnabled: boolean };

export enum VideoChannel {
  Default = 'default',
  Surround = 'surround',
  Reverse = 'reverse',
  Front = 'front',
  RealsenseLeft = 'realsense_left',
  RealsenseRight = 'realsense_right',
  _DepthFiltered = 'filtered_depth',
  _DepthUnfiltered = 'unfiltered_depth',
  _LocalizationDebugColor = 'localization_debug_color',
  _SemanticSegmentationEncodingFrontImage = 'semantic_segmentation_encoding_front',
  _SemanticSegmentationEncodingLeftImage = 'semantic_segmentation_encoding_left',
  _SemanticSegmentationEncodingRightImage = 'semantic_segmentation_encoding_right',
  _SemanticSegmentationEncodingRearImage = 'semantic_segmentation_encoding_rear',
  _LinesVisualizationFrontImage = 'lines_visualization_front',
  _LinesVisualizationLeftImage = 'lines_visualization_left',
  _LinesVisualizationRightImage = 'lines_visualization_right',
  _LinesVisualizationRearImage = 'lines_visualization_rear',
}

export interface GlobalPose {
  latitude: number;
  longitude: number;
  altitude: number;
  heading?: number;
}

export enum EndstopState {
  CurbClimbing = 'curb',
  Turning = 'turn',
}

export interface ImageCommand {
  type: string;
  timestamp?: Date;
  xFraction: number;
  yFraction: number;
}

export interface ManualRobotControl {
  speed: number;
  turnRate: number;
}
