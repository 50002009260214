import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperationLiveViewComponent } from './operation-live-view/operation-live-view.component';

import { MaterialModule } from '../material/material.module';
import { CoreModule } from '../core/core.module';
import { CreateOrderDialogComponent } from './create-order-dialog/create-order-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OperationsViewComponent } from './operations-view/operations-view.component';
import { CreateOperationDialogComponent } from './create-operation-dialog/create-operation-dialog.component';
import { HandoverControlSelectionViewComponent } from './handover-location-selection-view/handover-location-selection-view.component';
import { HandoverLocationViewComponent } from './handover-location-view/handover-location-view.component';
import { EditOperationDialogComponent } from './edit-operation-dialog/edit-operation-dialog.component';
import { AngularSplitModule } from 'angular-split';
import { OrderManagementDialog } from './order-management-dialog/order-management-dialog.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { CancelOrderDialogComponent } from './operation-live-view/cancel-order-dialog.component';
import { RobotCardComponent } from './handover-location-view/robot-card.component';
import { HandoverLocationTitleSplitterComponent } from './handover-location-view/handover-location-title-splitter.component';
import { CommunicationLogOverlayComponent } from './communication-log-overlay/communication-log-overlay.component';
import { MobileOperationLiveViewComponent } from './operation-live-view/mobile-operation-live-view.component';
import { DesktopOperationLiveViewComponent } from './operation-live-view/desktop-operation-live-view.component';
import { RobotOrderMapComponent } from './operation-live-view/robot-order-map.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    OperationLiveViewComponent,
    HandoverLocationViewComponent,
    HandoverControlSelectionViewComponent,
    CreateOrderDialogComponent,
    CreateOperationDialogComponent,
    OperationsViewComponent,
    EditOperationDialogComponent,
    OrderManagementDialog,
    CancelOrderDialogComponent,
    RobotCardComponent,
    HandoverLocationTitleSplitterComponent,
    CommunicationLogOverlayComponent,
    MobileOperationLiveViewComponent,
    DesktopOperationLiveViewComponent,
    RobotOrderMapComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularSplitModule,
    NgxMatIntlTelInputComponent,
    MatTabsModule,
  ],
  exports: [OrderManagementDialog],
})
export class OperationsModule {}
