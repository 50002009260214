import { LatLng, LatLngBounds, computeOffset } from 'spherical-geometry-js';
import { Location } from '../app/core/robots-service/backend/robot.dto';
import { GeoPoint } from '@cartken/map-types';

export function toGoogleLatLng([lng, lat]: GeoPoint):
  | google.maps.LatLng
  | undefined {
  if (lat === undefined || lng === undefined) {
    return;
  }
  return new google.maps.LatLng(lat, lng);
}

export function locationToCord(location: Location): [number, number] {
  return [location.longitude, location.latitude];
}

// It is generic since with have path represented with different kinds of points
// e.g. GoogleMap API LatLon, [number, number] and so on
export function closePolygon<T>(path?: T[]) {
  const firstPoint = path?.[0];
  if (firstPoint !== undefined) {
    path?.push(firstPoint);
  }
}

export function boundingPolygonFromBounds(bounds: LatLngBounds) {
  const minLng = bounds.getSouthWest().longitude;
  const minLat = bounds.getSouthWest().latitude;
  const maxLng = bounds.getNorthEast().longitude;
  const maxLat = bounds.getNorthEast().latitude;
  return [
    [minLng, minLat],
    [maxLng, minLat],
    [maxLng, maxLat],
    [minLng, maxLat],
    [minLng, minLat],
  ];
}

export function createBoundsFromCenterAndRadius(
  center: LatLng,
  radius: number,
) {
  const centerLiteral = {
    lat: center.lat(),
    lng: center.lng(),
  };
  const northEast = computeOffset(centerLiteral, radius, 45);
  const southWest = computeOffset(centerLiteral, radius, 45 + 180);
  const loadingRequestNorthEast = {
    lat: northEast.lat(),
    lng: northEast.lng(),
  };
  const loadingRequestSouthWest = {
    lat: southWest.lat(),
    lng: southWest.lng(),
  };
  const bounds = new LatLngBounds(
    loadingRequestSouthWest,
    loadingRequestNorthEast,
  );
  return bounds;
}

/**
 * Computes midPoint between two GeoPoints.
 *
 * If both altitudes are defined (not 0 or undefined), return mean altitude.
 * If only one is defined return that one.
 * Otherwise return undefined altitude.
 */
export function getMidPoint(a: GeoPoint, b: GeoPoint): GeoPoint {
  return lerpGeoPoint(a, b, 0.5);
}

/**
 * Linearly interpolate between two geo points element-wise.
 *
 * If both altitudes are defined, return interpolated altitude.
 * If only one is defined return that one.
 * Otherwise return undefined altitude.
 */
export function lerpGeoPoint(a: GeoPoint, b: GeoPoint, t: number): GeoPoint {
  const lng = a[0] + (b[0] - a[0]) * t;
  const lat = a[1] + (b[1] - a[1]) * t;
  const alt =
    a[2] !== undefined && b[2] !== undefined
      ? a[2] + (b[2] - a[2]) * t
      : (a[2] ?? b[2]);
  if (alt !== undefined) {
    return [lng, lat, alt];
  }
  return [lng, lat];
}
